import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Radio, Form, DatePicker, Button, Divider, notification, Select } from 'antd';
import commonService from '../../../services/commonService';
import urlConstant from '../../../constants/urlConstant';
import dataConstant from '../../../constants/dataConstant';
import messageConstant from '../../../constants/messageConstant';
import CustomeTableComponant from '../../../customeTableComponant';
const { Option, OptGroup } = Select;
let userRole;
let organizationId;
const dateFormat = dataConstant.Report.ReportDateFormat.dateFormat;

function DataLocation() {
  const child = React.createRef();
  const [performanceForm] = Form.useForm();
  const [searchBy, setSearchBy] = useState(dataConstant.Report.SearchByMonth);
  const [getAllLocationData, setAllLocationData] = useState({
    allLocationData: '',
    totalAllLocationData: 0,
  });
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [getFilterOption, setGetFilterOption] = useState('A');
  const [tableRequestParam, setTableRequestParam] = useState({
    pageNumber: dataConstant.tableRequest.pageNumber,
    rowsPerPage: dataConstant.tableRequest.rowsPerPage,
    searchColumns: [],
    filter: {
      country: '',
      state: '',
      city: '',
      pass: '',
      fail: '',
    },
    dataSetType: '',
    organizationId: '',
    fromDate: '',
    toDate: '',
    sortColumns: dataConstant.sortColumnsForCity,
  });

  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    organizationId = JSON.parse(LoginUserDetails).organizationId;
    performanceForm.setFieldsValue({
      searchBy: dataConstant.Report.SearchByMonth,
    });
    getOrganizationList();
    performanceForm.setFieldsValue({
      filterBy: 'A',
    });
  }, []);

  useEffect(() => {
    if (getFilterOption) {
      getAllScreeningData(tableRequestParam);
    }
  }, [getFilterOption]);

  const getOrganizationList = () => {
    commonService.httpGet(urlConstant.Organization.DropDownOrganization, true).then((response) => {
      if (response && response.data) {
        setOrganizationList(response.data);
      }
    });
  };

  const handleChange = (value) => {
    setGetFilterOption(value);
  };

  const onChange = (radioValue) => {
    setSearchBy(radioValue.target.value);
    performanceForm.setFieldsValue({
      fromDate: '',
      toDate: '',
    });
    if (radioValue.target.value === dataConstant.Report.SearchByMonth) {
      let newTableValue = tableRequestParam;
      newTableValue.fromDate = moment().startOf('year').format(dataConstant.Report.ApiDateFormat);
      newTableValue.toDate = moment().endOf('year').format(dataConstant.Report.ApiDateFormat);
      setTableRequestParam(newTableValue);
      getAllScreeningData(newTableValue);
    }
  };

  const getAllScreeningData = (requestObject) => {
    setIsLoaderVisible(true);

    /* Ternary Logic: - for DataSetType : Check if user role is "Client Admin" then pass "O" 
                        else check if user role is "Admin" and getFilterOption is "A" then pass "A" 
                        else check if user role is "Admin" and getFilterOption is "N" then pass "N" 
                        else pass "O" */

    const modelData = tableRequestParam;
    if (requestObject) {
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.filter = requestObject.filter ? requestObject.filter : tableRequestParam.filter;
      modelData.dataSetType =
        userRole === dataConstant.Role.ClientAdmin
          ? dataConstant.DataSetType.GetAllChartDataWithOrganizationId
          : userRole === dataConstant.Role.Admin && getFilterOption === dataConstant.DataSetType.GetAllChartData
          ? dataConstant.DataSetType.GetAllChartData
          : userRole === dataConstant.Role.Admin &&
            getFilterOption === dataConstant.DataSetType.GetAllChartDataWithNullId
          ? dataConstant.DataSetType.GetAllChartDataWithNullId
          : dataConstant.DataSetType.GetAllChartDataWithOrganizationId;
      (modelData.fromDate = modelData.fromDate
        ? modelData.fromDate
        : moment().startOf('year').format(dataConstant.Report.ApiDateFormat)),
        (modelData.toDate = modelData.toDate
          ? modelData.toDate
          : moment().endOf('year').format(dataConstant.Report.ApiDateFormat)),
        (modelData.organizationId =
          userRole === dataConstant.Role.ClientAdmin
            ? parseInt(organizationId)
            : userRole === dataConstant.Role.Admin &&
              (getFilterOption != dataConstant.DataSetType.GetAllChartData ||
                getFilterOption != dataConstant.DataSetType.GetAllChartDataWithNullId)
            ? parseInt(getFilterOption)
            : null);
    }
    commonService.httpPost(urlConstant.Organization.GetSearchValues, modelData, true).then((response) => {
      if (response && response.data) {
        setAllLocationData({
          allLocationData: response.data.list,
          totalAllLocationData: response.data.totalCount,
        });
        setIsLoaderVisible(false);
      }
    });
  };

  const onSubmit = (values) => {
    setIsLoaderVisible(true);
    if (values.fromDate >= values.toDate) {
      notification.warning({
        message: messageConstant.Report.ToDate,
        description: messageConstant.Report.DateValidation,
      });
    }
    if (values) {
      let newTableValue = tableRequestParam;
      newTableValue.fromDate = values.fromDate.format(dataConstant.Report.ReportDateFormat.dateFormat);
      newTableValue.toDate = values.toDate.format(dataConstant.Report.ReportDateFormat.dateFormat);
      getAllScreeningData(newTableValue);
    }
  };

  const Refresh = (current) => {
    let refreshTableData = tableRequestParam;
    (refreshTableData.pageNumber = dataConstant.tableRequest.pageNumber),
      (refreshTableData.rowsPerPage = dataConstant.tableRequest.rowsPerPage),
      (refreshTableData.searchColumns = []),
      (refreshTableData.filter = {
        country: '',
        state: '',
        city: '',
        pass: '',
        fail: '',
      }),
      (refreshTableData.dataSetType = ''),
      (refreshTableData.organizationId = ''),
      (refreshTableData.fromDate = ''),
      (refreshTableData.toDate = ''),
      (refreshTableData.sortColumns = dataConstant.sortColumnsForCity),
      getAllScreeningData(refreshTableData);
  };

  const columnsForAllData = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 200,
      sorter: true,
      dataType: 'StringContains',
      render: (text) => <span>{text === null || text === '' ? <span>NA</span> : <span>{text}</span>}</span>,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 150,
      sorter: true,
      dataType: 'StringContains',
      render: (text) => <span>{text === null || text === '' ? <span>NA</span> : <span>{text}</span>}</span>,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
      render: (text) => <span>{text === null || text === '' ? <span>NA</span> : <span>{text}</span>}</span>,
    },
    {
      title: 'Passed',
      dataIndex: 'pass',
      key: 'pass',
      sorter: true,
      width: 100,
      dataType: 'StringContains',
    },
    {
      title: 'Failed',
      dataIndex: 'fail',
      key: 'fail',
      sorter: true,
      width: 100,
      dataType: 'StringContains',
    },
  ];

  return (
    <>
      <div className="page-header">
        <h1>Data Location</h1>
      </div>
      <div className="search_organization_wrapper cm-dataLocation">
        <Form className="row" form={performanceForm} onFinish={onSubmit}>
          {userRole === dataConstant.Role.Admin && (
            <Form.Item
              required={false}
              label="Organization"
              name="filterBy"
              id="filterBy"
              validateTrigger="onChange"
              className="col-12"
              rules={[
                {
                  required: true,
                  message: messageConstant.Report.FilterValidation,
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children === undefined ? false : option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children === undefined
                    ? false
                    : optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                style={{
                  width: 200,
                }}
                onChange={handleChange}
              >
                <OptGroup label="Admin option">
                  <Option value="A">All Data</Option>
                  <Option value="N">Non-Oragnization Data</Option>
                </OptGroup>
                <OptGroup label="Organization">
                  {organizationList.length > 0 &&
                    organizationList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            </Form.Item>
          )}
          <Form.Item valuePropName="checked" className="col-md-4" id="searchBy" name="searchBy">
            <Radio.Group onChange={onChange} value={searchBy}>
              <Radio value="searchByMonth">Screening Performance of Current Year</Radio>
              <Radio value="searchByDate">Screening Performance by Date</Radio>
            </Radio.Group>
          </Form.Item>
          {searchBy == 'searchByDate' ? (
            <div className="col-md-8 date_picker_wrapper">
              <Form.Item
                id="fromDate"
                name="fromDate"
                label="From"
                rules={[{ required: true, message: messageConstant.Report.FromDateValidation }]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(current) => {
                    return current && current > moment().endOf('day');
                  }}
                />
              </Form.Item>
              <Form.Item
                id="toDate"
                name="toDate"
                label="To"
                rules={[{ required: true, message: messageConstant.Report.ToDateValidation }]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(current) => {
                    return current && current < performanceForm.getFieldValue('fromDate');
                  }}
                />
              </Form.Item>
              {searchBy == 'searchByDate' && (
                <Button className="btn btn-outline-secondary mx-auto" type="primary" htmlType="submit">
                  Show
                </Button>
              )}
            </div>
          ) : null}
        </Form>
        <Divider />
        <CustomeTableComponant
          ref={child}
          rowKey="testResultId"
          columns={columnsForAllData}
          dataSource={getAllLocationData.allLocationData}
          getGridData={getAllScreeningData}
          totalCount={getAllLocationData.totalAllLocationData}
          scroll={{ x: 'max-content' }}
          loading={isLoaderVisible}
          PageNumber={tableRequestParam.pageNumber}
          refresh={(current) => Refresh(current)}
        />
      </div>
    </>
  );
}

export default DataLocation;
