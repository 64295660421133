/* eslint-disable no-console */
export const httpGet = async (requestURL, authorizationRequired, enableLoader = true) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'get',
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        Authorization: '',
      },
    };
    try {
      if (authorizationRequired) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
          logout();
        }
        options.headers.Authorization = `bearer ${user.token}`;
      }
      if (enableLoader) {
        // startApiLoader()
      }
      fetch(requestURL, options)
        .then(
          (response) => {
            if (response.status === 404 || response.status === 200 || response.status === 400) {
              return response.json();
            }
            return response;
          },
          (err) => {
            reject(err);
          }
        )
        .then(
          (responseJson) => {
            if (responseJson.statusCode === 401) {
              logout();
            }
            resolve(responseJson);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          if (enableLoader) {
            // endApiLoader()
          }
        });
    } catch (error) {
      console.warn(error);
    }
  });

export const httpPost = async (requestURL, requestBody, authorizationRequired, enableLoader = true) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'post',
      headers: {
        'content-type': 'application/json; charset=UTF-8',
        Authorization: '',
      },
      body: JSON.stringify(requestBody),
    };

    try {
      if (authorizationRequired) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
          logout();
        }
        options.headers.Authorization = `bearer ${user.token}`;
      }
      if (enableLoader) {
        // startApiLoader()
      }
      fetch(requestURL, options)
        .then(
          (response) => {
            if (response.status === 404 || response.status === 200 || response.status === 400) {
              return response.json();
            }
            return response;
          },
          (err) => {
            reject(err);
          }
        )
        .then(
          (responseJson) => {
            if (responseJson.statusCode === 401) {
              logout();
            }
            resolve(responseJson);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          if (enableLoader) {
            // endApiLoader()
          }
        });
    } catch (error) {
      console.warn(error);
    }
  });

export const FileUpload = async (requestURL, formData, authorizationRequired, enableLoader = true) =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    };

    if (enableLoader) {
      // startApiLoader()
    }
    try {
      if (authorizationRequired) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
          logout();
        }
        options.headers.Authorization = `bearer ${user.token}`;
      }
      if (enableLoader) {
        // startApiLoader()
      }
      fetch(requestURL, options)
        .then(
          (response) => {
            if (response.status === 404 || response.status === 200 || response.status === 400) {
              return response.json();
            }
            return response;
          },
          (err) => {
            reject(err);
          }
        )
        .then(
          (responseJson) => {
            if (responseJson.statusCode === 401) {
              logout();
            }
            resolve(responseJson);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          if (enableLoader) {
            // endApiLoader()
          }
        });
    } catch (error) {
      console.warn(error);
    }
  });

export const GetFile = async (requestURL, enableLoader = true) =>
  new Promise((resolve, reject) => {
    const options = {
      responseType: 'blob',
      method: 'get',
      headers: {
        'content-type': 'application/json;',
      },
    };

    if (enableLoader) {
      // startApiLoader()
    }
    try {
      fetch(requestURL, options)
        .then(
          (response) => {
            if (response.status === 404 || response.status === 200 || response.status === 400) {
              return response;
            }
            return response;
          },
          (err) => {
            reject(err);
          }
        )
        .then(
          (responseJson) => {
            if (responseJson.statusCode === 401) {
              logout();
            }
            resolve(responseJson);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          if (enableLoader) {
            // endApiLoader()
          }
        });
    } catch (error) {
      console.warn(error);
    }
  });

export const logout = () => {
  localStorage.removeItem('user');
  window.location.href = `/admin/login`;
  return;
};

const commonService = {
  httpGet,
  httpPost,
  FileUpload,
  GetFile,
};

export default commonService;
