import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Radio, Form, DatePicker, Button, Divider, notification, Select } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import urlConstant from '../../../constants/urlConstant';
import dataConstant from '../../../constants/dataConstant';
import { httpGet, httpPost } from '../../../services/commonService';
import _ from 'lodash';
import moment from 'moment';
import messageConstant from '../../../constants/messageConstant';
const url = urlConstant.Organization.PassFailDataByDate;
const { Option, OptGroup } = Select;
let userRole;
let organizationId;
const dateFormat = dataConstant.Report.ReportDateFormat.dateFormat;
Exporting(Highcharts);

const PassFailData = () => {
  const [performanceForm] = Form.useForm();
  const [searchBy, setSearchBy] = useState(dataConstant.Report.SearchByMonth);
  const [organizationList, setOrganizationList] = useState([]);
  const [filterOption, setFilterOption] = useState('A');
  const [getfilterDate, setGetfilterDate] = useState({
    startDate: moment().startOf('year').format(dataConstant.Report.ApiDateFormat), // get current year start date
    endDate: moment().endOf('year').format(dataConstant.Report.ApiDateFormat), // get current year end date
  });
  const [chartData, setChartData] = useState({
    month: [],
    totalCount: [],
    Pass: [],
    Fail: [],
  });

  // get user role and organization id from localstorage and set to global variable
  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    organizationId = JSON.parse(LoginUserDetails).organizationId;
    // set radio button value to search by month
    performanceForm.setFieldsValue({
      searchBy: dataConstant.Report.SearchByMonth,
    });
    getOrganizationList();
    // set organization dropdown value to Show All
    performanceForm.setFieldsValue({
      filterBy: 'A',
    });
  }, []);

  // get chart data by change in oraganization dropdown
  useEffect(() => {
    if (filterOption) {
      getChartDetails(getfilterDate.startDate, getfilterDate.endDate);
    }
  }, [filterOption]);

  // get chart data by change in radio button value
  const onChange = (radioValue) => {
    setSearchBy(radioValue.target.value);
    if (radioValue.target.value === dataConstant.Report.SearchByMonth) {
      getChartDetails();
    }
  };

  // Api call for get organization Pass Fail data with passing start date and end date
  const getChartDetails = (fromDateFormat, toDateFormat) => {
    setGetfilterDate({
      startDate: fromDateFormat ? fromDateFormat : moment().startOf('year').format(dataConstant.Report.ApiDateFormat),
      endDate: toDateFormat ? toDateFormat : moment().endOf('year').format(dataConstant.Report.ApiDateFormat),
    });
    {
      /* Ternary Logic: - for DataSetType : Check if user role is "Client Admin" then pass "O" 
                          else check if user role is "Admin" and filterOption is "A" then pass "A" 
                          else check if user role is "Admin" and filterOption is "N" then pass "N" 
                          else pass "O" */
    }
    const data = {
      DataSetType:
        userRole === dataConstant.Role.ClientAdmin
          ? dataConstant.DataSetType.GetAllChartDataWithOrganizationId
          : userRole === dataConstant.Role.Admin && filterOption === dataConstant.DataSetType.GetAllChartData
          ? dataConstant.DataSetType.GetAllChartData
          : userRole === dataConstant.Role.Admin && filterOption === dataConstant.DataSetType.GetAllChartDataWithNullId
          ? dataConstant.DataSetType.GetAllChartDataWithNullId
          : dataConstant.DataSetType.GetAllChartDataWithOrganizationId,
      FromDate: fromDateFormat ? fromDateFormat : moment().startOf('year').format(dataConstant.Report.ApiDateFormat),
      ToDate: toDateFormat ? toDateFormat : moment().endOf('year').format(dataConstant.Report.ApiDateFormat),
      OrganizationId:
        userRole === dataConstant.Role.ClientAdmin
          ? parseInt(organizationId)
          : userRole === dataConstant.Role.Admin &&
            (filterOption != dataConstant.DataSetType.GetAllChartData ||
              filterOption != dataConstant.DataSetType.GetAllChartDataWithNullId)
          ? parseInt(filterOption)
          : null,
    };
    httpPost(url, data, true)
      .then((response) => {
        if (response.status === dataConstant.APIStatus.Success) {
          if (response.data) {
            setChartData({
              month: _.map(response.data, function (value) {
                return value.month + '\n' + value.year;
              }),
              totalCount: _.map(response.data, 'totalCount'),
              Pass: _.map(response.data, 'pass'),
              Fail: _.map(response.data, 'fail'),
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // on date picker button click
  const onSubmit = (values) => {
    if (values.fromDate >= values.toDate) {
      notification.warning({
        message: messageConstant.Report.ToDate,
        description: messageConstant.Report.DateValidation,
      });
    }
    var fromDateFormat = values.fromDate
      ? values.fromDate.format(dataConstant.Report.ReportDateFormat.dateFormat)
      : null;
    var toDateFormat = values.toDate ? values.toDate.format(dataConstant.Report.ReportDateFormat.dateFormat) : null;

    getChartDetails(fromDateFormat, toDateFormat);
  };

  // set value of organization dropdown
  const handleChange = (value) => {
    setFilterOption(value);
  };

  // get organization list from api call via useEffect
  const getOrganizationList = () => {
    httpGet(urlConstant.Organization.DropDownOrganization, true).then((response) => {
      if (response && response.data) {
        setOrganizationList(response.data);
      }
    });
  };

  return (
    <>
      <div className="page-header">
        <h1>Pass/Fail Data</h1>
      </div>
      <div className="search_organization_wrapper cm-passfail">
        <Form className="row" form={performanceForm} onFinish={onSubmit}>
          {userRole === dataConstant.Role.Admin && (
            <Form.Item
              required={false}
              label="Organization"
              name="filterBy"
              id="filterBy"
              validateTrigger="onChange"
              className="col-12"
              rules={[
                {
                  required: true,
                  message: messageConstant.Report.FilterValidation,
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children === undefined ? false : option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children === undefined
                    ? false
                    : optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                style={{
                  width: 200,
                }}
                onChange={handleChange}
              >
                <OptGroup label="Admin option">
                  <Option value="A">All Data</Option>
                  <Option value="N">Non-Oragnization Data</Option>
                </OptGroup>
                <OptGroup label="Organization">
                  {organizationList.length > 0 &&
                    organizationList.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            </Form.Item>
          )}
          <Form.Item className="col-md-4" id="searchBy" name="searchBy">
            <Radio.Group onChange={onChange} value={searchBy}>
              <Radio value="searchByMonth">Screening Performance of Current Year</Radio>
              <Row>
                <Radio value="searchByDate">Screening Performance by Date</Radio>
              </Row>
            </Radio.Group>
          </Form.Item>
          {searchBy == 'searchByDate' ? (
            <div className="col-md-8 date_picker_wrapper">
              <Form.Item
                id="fromDate"
                name="fromDate"
                label="From"
                rules={[{ required: true, message: messageConstant.Report.FromDateValidation }]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(current) => {
                    return current && current > moment().endOf('day');
                  }}
                />
              </Form.Item>
              <Form.Item
                id="toDate"
                name="toDate"
                label="To"
                rules={[{ required: true, message: messageConstant.Report.ToDateValidation }]}
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={(current) => {
                    return current && current < performanceForm.getFieldValue('fromDate');
                  }}
                />
              </Form.Item>
              {searchBy == 'searchByDate' && (
                <Button className="btn btn-outline-secondary mx-auto" type="primary" htmlType="submit">
                  Show
                </Button>
              )}
            </div>
          ) : null}
        </Form>
        <Divider />

        <Row>
          <Col span={24}>
            <Card>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'column',
                  },
                  title: {
                    text: 'Screening Performance',
                  },
                  xAxis: {
                    categories: chartData.month,
                    crosshair: true,
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: 'Number of Person',
                    },
                  },

                  tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat:
                      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                      '<td style="padding:0"><b>{point.y}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true,
                  },
                  plotOptions: {
                    column: {
                      pointPadding: 0.2,
                      borderWidth: 0,
                    },
                  },
                  series: [
                    {
                      color: 'gray',
                      name: 'Pass',
                      data: chartData.Pass,
                    },
                    {
                      color: 'red',
                      name: 'Fail',
                      data: chartData.Fail,
                    },
                  ],
                  credits: {
                    enabled: false,
                  },
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PassFailData;
