import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import 'antd/dist/antd.css';
import { Table, Tooltip, Button, Input } from 'antd';
import dataConstant from './constants/dataConstant';
import { SearchOutlined, ReloadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const NewTableComponent = ({
  rowKey,
  columns,
  dataSource,
  getGridData,
  totalCount,
  scroll,
  refresh,
  isShowAddButton,
  addCallbackMethod,
  deleteCallbackMethod,
  SearchText,
  pageNumber,
  enableRowSelection,
  isShowMultiDelete,
  onSelectChange,
  loading = false,
  expandedRowRender,
  tableRequest,
}) => {
  const [request, setRequest] = useState(JSON.parse(JSON.stringify(dataConstant.resultTableRequest)));
  const [pagination, setPagination] = useState({
    current: dataConstant.pagination.pageNumber,
    defaultPageSize: JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.resultTableRequest.rowsPerPage,
    showSizeChanger: dataConstant.pagination.showSizeChanger,
    pageSizeOptions: dataConstant.pagination.pageSizeOptions,
    total: 0,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isMultipleDelete, setIsMultipleDelete] = useState(false);
  const [Columns, setColumns] = useState([]);

  useEffect(() => {
    setRequest(tableRequest);
    setPagination({ ...pagination, current: pageNumber, total: totalCount });
    setColumns(columns);
  }, [pageNumber, SearchText, totalCount, tableRequest]);

  const getGridDatas = (resetData = false, pageNumber = 1) => {
    if (resetData) {
      setRequest(JSON.parse(JSON.stringify(dataConstant.resultTableRequest)));
      setPagination({
        current: dataConstant.pagination.pageNumber,
        defaultPageSize: JSON.parse(localStorage.getItem('RowsPerPage'))
          ? JSON.parse(localStorage.getItem('RowsPerPage'))
          : dataConstant.resultTableRequest.rowsPerPage,
        showSizeChanger: dataConstant.pagination.showSizeChanger,
        pageSizeOptions: dataConstant.pagination.pageSizeOptions,
        total: 0,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      });
      setSelectedRowKeys([]);
      setIsMultipleDelete(false);
      getGridData(JSON.parse(JSON.stringify(dataConstant.resultTableRequest)), pageNumber);
    } else {
      getGridData(request, pageNumber);
    }
  };

  const handleTableChange = (pager, filters, sorter) => {
    const { current, pageSize } = pager;

    setPagination({ ...pagination, current: current });
    setSelectedRowKeys([]);
    setIsMultipleDelete(false);

    const requestObject = {
      pageNumber: current,
      rowsPerPage: pageSize,
      SearchText: SearchText,
      SearchColumns: [],
      sortColumns: {
        sortColumnName: sorter.field || 'id',
        sortOrder: (sorter.order === 'ascend' ? 'asc' : '') || (sorter.order === 'descend' ? 'desc' : '') || 'desc',
      },
    };
    localStorage.setItem('RowsPerPage', JSON.stringify(pageSize));
    getGridData(requestObject, current);
    if (onSelectChange) {
      onSelectChange([], []);
    }
  };

  const pageRefresh = () => {
    getGridData(JSON.parse(JSON.stringify(dataConstant.resultTableRequest)));
    setPagination({
      current: dataConstant.pagination.pageNumber,
      defaultPageSize: JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.resultTableRequest.rowsPerPage,
      showSizeChanger: dataConstant.pagination.showSizeChanger,
      pageSizeOptions: dataConstant.pagination.pageSizeOptions,
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    });
    setSelectedRowKeys([]);
    setIsMultipleDelete(false);
  };

  const handleSearch = (selectedKeys, dataIndex, dataType, isQuickSearch) => {
    const searchobject = _.filter(request.SearchColumns, (item) => {
      return item.isQuickSearch !== true;
    });

    const objSearchData = {
      searchedColumn: dataIndex,
      searchText: !isQuickSearch ? selectedKeys[0] && selectedKeys[0].trim() : selectedKeys,
      ColumnDataType: dataType,
      isQuickSearched: isQuickSearch,
    };

    setRequest({
      ...request,
      PageNumber: dataConstant.pagination.pageNumber,
      SearchColumns: searchobject.push(objSearchData),
    });

    getGridDatas(false, dataConstant.pagination.pageNumber);
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    const searchobject = _.filter(request.SearchColumns, (item) => {
      return item.SearchColumns !== dataIndex;
    });

    setRequest({
      ...request,
      PageNumber: dataConstant.pagination.pageNumber,
      SearchColumns: searchobject,
    });

    setPagination({
      ...pagination,
      current: dataConstant.pagination.pageNumber,
    });

    getGridDatas(false, dataConstant.pagination.pageNumber);
  };

  const searchedColumnIndex = (dataIndex) => {
    return request.SearchColumns.findIndex((item) => item.searchedColumn === dataIndex);
  };

  const addCallback = () => {
    addCallbackMethod();
  };

  const deleteCallback = () => {
    deleteCallbackMethod(selectedRowKeys, isMultipleDelete);
  };

  const onSelectChanges = (selectedRowKeys, selectedRows) => {
    if (selectedRowKeys.length > 0) {
      setSelectedRowKeys(selectedRowKeys);
      setIsMultipleDelete(true);
    } else {
      setSelectedRowKeys([]);
      setIsMultipleDelete(false);
    }

    if (onSelectChange) {
      onSelectChange(selectedRowKeys, selectedRows);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChanges,
  };
  return (
    <>
      <Table
        rowKey={(record) => record[rowKey]}
        columns={Columns}
        dataSource={dataSource}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        rowSelection={enableRowSelection ? rowSelection : null}
        size="middle"
        scroll={scroll}
        bordered
        expandedRowRender={expandedRowRender}
        title={() => (
          <div className="text-right">
            {isShowMultiDelete && isMultipleDelete && (
              <Tooltip placement="bottom" title="Delete">
                <Button
                  icon={<DeleteOutlined />}
                  className="ant-btn mr-1 ant-btn-sm ant-btn-icon-only"
                  onClick={() => deleteCallback()}
                />
              </Tooltip>
            )}
            <Tooltip placement="bottom" title="Refresh">
              <Button className="ant-btn mr-1 ant-btn-sm ant-btn-icon-only" onClick={pageRefresh}>
                <ReloadOutlined />
                <i className="fa fa-refresh" />
              </Button>
            </Tooltip>
            {isShowAddButton && (
              <Tooltip placement="bottom" title="Add">
                <Button
                  icon={<PlusOutlined />}
                  className="ant-btn mr-1 ant-btn-sm ant-btn-icon-only"
                  onClick={() => addCallback()}
                >
                  <i className="fa fa-plus" />
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      />
    </>
  );
};

export default NewTableComponent;
