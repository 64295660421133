import {
  CLEAR_ALL_DATA,
  CLEAR_TABLE_DATA,
  TestResultData,
  Api_Call,
  Api_Call_Scent,
  TestKitNameList,
  Api_Url_Scent_TestResult,
  NameData,
  Api_Url_Scent_TestKit_Save,
  Api_Rul_GetScentCardDetailById,
  apiActions,
} from '../action/actions';

const initialState = {
  isLoading: false,
  roles: [],
  res: false,
  sentCard: [],
  testResult: [],
  testKit: [],
  name: '',
  scentCardDetails: [],
  testKitNameList: [],
  testResultDataList: [],
};
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Api_Call:
      return {
        ...state,
        roles: action.payload,
        res: true,
      };
    case Api_Call_Scent:
      return {
        ...state,
        sentCard: action.payload,
        res: true,
      };
    case Api_Url_Scent_TestResult:
      return {
        ...state,
        testResult: action.payload,
        res: true,
      };

    case Api_Url_Scent_TestKit_Save:
      return {
        ...state,
        testKit: action.payload,
        res: true,
      };
    case NameData:
      return {
        name: action.payload,
      };
    case Api_Rul_GetScentCardDetailById:
      return {
        ...state,
        scentCardDetails: action.payload,
        res: true,
      };
    case apiActions.SET_STATE:
      return {
        ...state,
        isLoading: action.payload,
        res: true,
      };
    case TestKitNameList:
      return {
        ...state,
        testKitNameList: action.payload,
        res: true,
      };

    case TestResultData:
      return {
        ...state,
        testResultDataList: action.payload,
        res: true,
      };
    case CLEAR_TABLE_DATA:
      return {
        ...state,
        testResult: [],
        res: true,
      };

    case CLEAR_ALL_DATA:
      return {
        ...state,
        sentCard: [],
        res: true,
      };
    default:
      return state;
  }
};
