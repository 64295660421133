/* eslint-disable no-empty */
import urlConstant from '../../constants/urlConstant';
import { httpGet, httpPost } from '../../services/commonService';
export const Api_Call = 'Api_Call';
export const Api_Call_Scent = 'Api_Call_Scent';
export const Api_Url_Scent_TestResult = 'Api_Url_Scent_TestResult';
export const Api_Url_Scent_TestKit_Save = 'Api_Url_Scent_TestKit_Save';
export const Api_Rul_GetScentCardDetailById = 'Api_Rul_GetScentCardDetailById';
export const TestKitNameList = 'TestKitNameList';
export const TestResultData = 'TestResultData';
export const SET_STATE = 'SET_STATE';
export const NameData = 'NameData';
export const CLEAR_TABLE_DATA = 'CLEAR_TABLE_DATA';
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';

const getRoles = urlConstant.Role.getRoles;
const getScentCardDetail = urlConstant.ScentCard.GetSentCardDetails;
const saveTestResult = urlConstant.TestResult.SaveTestResult;
const postTestKitName = urlConstant.TestKit.CreateTestkit;

export const getApiCallData = () => {
  return async (dispatch) => {
    const response = await httpGet(getRoles);
    const data = await response.json();
    if (data) {
      dispatch({
        type: Api_Call,
        payload: data,
      });
    } else {
    }
  };
};

export const getScentApiData = (id) => {
  return async (dispatch) => {
    localStorage.removeItem('data');
    const response = await httpGet(`${getScentCardDetail}` + id);
    const data = await response;
    if (data) {
      dispatch({
        type: Api_Call_Scent,
        payload: data,
      });
    } else {
    }
  };
};

export const postTestResult = (json) => {
  return async (dispatch) => {
    const response = await // fetch("https://localhost:44303/api/TestResult/SaveTestResult", requestOptions)
    httpPost(`${saveTestResult}`, json);
    const data = await response;
    if (data) {
      dispatch({
        type: Api_Url_Scent_TestResult,
        payload: data,
      });
    } else {
    }
  };
};

export const CreateTestkit = (json) => {
  return async (dispatch) => {
    const response = await // fetch("https://localhost:44303/api/TestResult/SaveTestResult", requestOptions)
    httpPost(`${postTestKitName}`, json);
    const data = await response;
    if (data) {
      dispatch({
        type: Api_Url_Scent_TestKit_Save,
        payload: data,
      });
    } else {
    }
  };
};

export const apiActions = {
  SET_STATE: 'apiLoader/SET_STATE',
};

export const getScentCardDetailById = (id) => {
  return async (dispatch) => {
    const response = await httpGet(`${urlConstant.ScentCard.GetScentCardDetailById}` + id, true);
    const data = await response;
    if (data) {
      dispatch({
        type: Api_Rul_GetScentCardDetailById,
        payload: data,
      });
    } else {
    }
  };
};

export const getTestNameList = () => {
  return async (dispatch) => {
    const response = await httpGet(`${urlConstant.TestKit.GetTestNameList}`, true);
    const data = await response;
    if (data) {
      dispatch({
        type: TestKitNameList,
        payload: data,
      });
    } else {
    }
  };
};

export const getTestResultData = (modelData) => {
  return async (dispatch) => {
    const response = await httpPost(urlConstant.TestResult.GetTestResultList, modelData, true);
    const data = await response;
    if (data) {
      dispatch({
        type: TestResultData,
        payload: data,
      });
    } else {
    }
  };
};

export const clearTableData = () => {
  return { type: CLEAR_TABLE_DATA };
};

export const clearAllData = () => {
  return { type: CLEAR_ALL_DATA };
};
