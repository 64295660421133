import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Tooltip, Button, Modal, notification, Form, Row, Input, Tag } from 'antd';
import { debounce } from 'lodash';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined, UnlockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import TableComponent from '../../../TableComponent';
import dataConstant from '../../../constants/dataConstant';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';
import messageConstant from '../../../constants/messageConstant';

let userId = '';

const User = () => {
  const userGrid = useRef();
  const [form] = Form.useForm();
  const [resetForm] = Form.useForm();
  const history = useHistory();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [userList, setUserList] = useState({
    count: 0,
    data: [],
  });
  const [searchText, setSearchText] = useState('');
  const [resetModal, setResetModal] = useState(false);
  const debounceFilterData = useMemo(() => debounce(applyFilter, 1000), []);
  const initRequestParams = {
    pageNumber: dataConstant.tableRequest.pageNumber,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage,
    filter: {
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
    },
    sortColumns: dataConstant.tableRequest.sortColumns,
  };
  const [tableRequestParam, setTableRequestParam] = useState(initRequestParams);
  const messageTitle = 'User';

  const resetPassword = (id) => {
    userId = id;
    setResetModal(true);
  };

  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: true,
      align: 'center',
      width: 100,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Edit">
            <Button
              icon={<EditOutlined />}
              className="mr-1"
              size="small"
              onClick={() => editUser(record.id, record.name)}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Reset Password">
            <Button icon={<UnlockOutlined />} className="mr-1" size="small" onClick={() => resetPassword(record.id)} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
    },
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
      // sorter: true,
      align: 'center',
      width: 200,
      dataType: 'StringContains',
      render: (text) => <span>{text === null || text === '' ? <span>-</span> : <span>{text}</span>}</span>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
      width: 150,
      dataType: 'StringContains',
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      width: 150,
      render: (isActive) => (
        <>
          {!isActive && <Tag color="#f50">Inactive</Tag>}
          {isActive && <Tag color="#87d068">Active</Tag>}
        </>
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      fixed: 'right',
      align: 'center',
      width: 30,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Delete">
            <Button icon={<DeleteOutlined />} size="small" onClick={() => confirmDeleteUser(record.id, false)} />
          </Tooltip>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (searchText !== '') {
      debounceFilterData(searchText);
    } else {
      gridRefresh();
    }
  }, [searchText]);

  function applyFilter(SearchValue) {
    if (SearchValue !== '') {
      const filter = {};
      filter.FirstName = SearchValue;
      filter.LastName = SearchValue;
      filter.Email = SearchValue;
      filter.Phone = SearchValue;
      const searchModelData = tableRequestParam;
      searchModelData.filter = filter;
      searchModelData.pageNumber = dataConstant.tableRequest.pageNumber;
      searchModelData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.pagination.defaultPageSize;
      getUserList(tableRequestParam);
    }
  }
  const gridRefresh = () => {
    form.resetFields();
    initRequestParams.pageNumber = dataConstant.tableRequest.pageNumber;
    initRequestParams.rowsPerPage = dataConstant.tableRequest.rowsPerPage;
    initRequestParams.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage;
    initRequestParams.filter = {
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
    };
    initRequestParams.sortColumns = dataConstant.tableRequest.sortColumns;
    setTableRequestParam(initRequestParams);
    getUserList(initRequestParams);
  };

  const getUserList = (requestObject) => {
    setIsLoaderVisible(true);
    const modelData = tableRequestParam;

    if (requestObject) {
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.filter = requestObject.filter ? requestObject.filter : tableRequestParam.filter;
    }
    commonService.httpPost(urlConstant.User.GetUserList, modelData, true, true).then((response) => {
      if (response && response.data) {
        setUserList({
          data: response.data.list,
          count: response.data.totalCount,
        });
        setIsLoaderVisible(false);
      }
    });
  };

  const redirectToAddUser = () => {
    history.push({
      pathname: `/admin/manageuser`,
    });
  };

  const Refresh = (current) => {
    const initRequestParams = {
      pageNumber: 1,
      rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage,
      filter: {
        FirstName: '',
        LastName: '',
        Email: '',
        Phone: '',
      },
      sortColumns: dataConstant.tableRequest.sortColumns,
    };
    setSearchText('');
    form.resetFields();
    setTableRequestParam(initRequestParams);
    getUserList(initRequestParams);
  };

  const editUser = (id, name) => {
    history.push({
      pathname: `/admin/manageuser/${id}`,
      state: name,
    });
  };

  const confirmDeleteUser = (id, isMultipleDelete) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteUser(isMultipleDelete ? id.join(',') : id.toString());
      },
    });
  };

  const deleteUser = (Ids) => {
    commonService.httpGet(`${urlConstant.User.DeleteUser}?Id=${Ids}`, true).then((response) => {
      if (response.status === dataConstant.APIStatus.Success) {
        notification.success({
          message: messageTitle,
          description: response.message,
        });
        getUserList();
      } else if (response.status === dataConstant.APIStatus.Failure) {
        notification.warning({
          message: messageTitle,
          description: response.error,
        });
      }
    });
  };

  const closeResetModal = () => {
    setResetModal(false);
    resetForm.resetFields();
  };

  const saveResetPassword = () => {
    resetForm.validateFields().then(async (resetPasswordData) => {
      resetPasswordData.UserId = userId;
      if (resetPasswordData) {
        commonService.httpPost(urlConstant.User.ResetPassword, resetPasswordData, true).then((response) => {
          if (response.status === dataConstant.APIStatus.Success) {
            notification.success({
              message: 'Password',
              description: response.message,
            });
            closeResetModal();
          } else if (response.status === dataConstant.APIStatus.Failure) {
            notification.warning({
              message: 'Password',
              description: response.message,
            });
          }
        });
        resetForm.resetFields();
      }
    });
  };

  return (
    <div>
      <Modal
        visible={resetModal}
        title={`Reset Password`}
        centered
        okText="Reset"
        width={500}
        onOk={saveResetPassword}
        onCancel={closeResetModal}
      >
        <Form form={resetForm} className="cm-country-form">
          <Form.Item id="id" name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item
            id="Password"
            name="Password"
            label="New Password"
            rules={[
              { required: true, message: dataConstant.resetPassword.newPasswordRequired },
              {
                pattern: dataConstant.passwordPattern,
                message: dataConstant.resetPassword.newPasswordMessage,
              },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['Password']}
            rules={[
              {
                required: true,
                message: `${messageConstant.Required} Confirm Password`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('Password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(messageConstant.ConfirmPassword));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
      <div className="page-header">
        <h1>Users</h1>
        <Form form={form}>
          <Row justify="end">
            <Form.Item id="data" label="Search" name="searchtext" className="col-12 col-md-6">
              <div className="d-flex">
                <Input
                  placeholder="Search by First Name/Last Name/Email/Phone"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={() => setSearchText('')}
                  htmlType="submit"
                  type="primary"
                />
              </div>
            </Form.Item>
          </Row>
        </Form>
      </div>
      <TableComponent
        ref={userGrid}
        rowKey="id"
        columns={columns}
        dataSource={userList.data}
        getGridData={getUserList}
        totalCount={userList.count}
        scroll={{ x: 'max-content' }}
        loading={isLoaderVisible}
        refresh={(current) => Refresh(current)}
        isShowAddButton
        addCallbackMethod={redirectToAddUser}
        deleteCallbackMethod={confirmDeleteUser}
        pageNumber={tableRequestParam.pageNumber}
      />
    </div>
  );
};

export default User;
