import React, { useEffect, useState, useMemo } from 'react';
import { Tooltip, Button, Modal, notification, Form, Row, Input } from 'antd';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import TableComponent from '../../../TableComponent';
import dataConstant from '../../../constants/dataConstant';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';
import messageConstant from '../../../constants/messageConstant';
let userRole;

export default function TestKit() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [testKitData, setTestKitData] = useState({
    testKitTotalCount: 0,
    testKitData: [],
  });

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFilterData = useMemo(() => debounce(applyFilter, 1000), []);
  const [tableRequestParam, setTableRequestParam] = useState({
    pageNumber: dataConstant.tableRequest.pageNumber,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage,
    filter: {
      Name: '',
    },
    sortColumns: dataConstant.tableRequest.sortColumns,
  });
  const child = React.createRef();

  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchText !== '') {
      debounceFilterData(searchText);
    } else {
      gridRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const gridRefresh = () => {
    let refreshTableData = tableRequestParam;
    refreshTableData.pageNumber = dataConstant.tableRequest.pageNumber;
    refreshTableData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage;
    refreshTableData.filter = {
      Name: '',
    };
    refreshTableData.sortColumns = dataConstant.tableRequest.sortColumns;
    setTableRequestParam(refreshTableData);
    getTestKitData(refreshTableData);
    setSearchText('');
    form.resetFields();
  };

  function applyFilter(SearchValue) {
    if (SearchValue !== '') {
      const filter = {};
      filter.Name = SearchValue;
      let searchModelData = tableRequestParam;
      searchModelData.filter = filter;
      searchModelData.pageNumber = dataConstant.tableRequest.pageNumber;
      searchModelData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage;

      getTestKitData(tableRequestParam);
    }
  }

  const getTestKitData = (requestObject) => {
    setIsLoaderVisible(true);

    const modelData = tableRequestParam;

    if (requestObject) {
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.filter = requestObject.filter ? requestObject.filter : tableRequestParam.filter;
    }
    commonService.httpPost(urlConstant.TestKit.GetTestKit, modelData, true, true).then((response) => {
      if (response && response.data) {
        setTestKitData({
          testKitData: response.data.list,
          testKitTotalCount: response.data.totalCount,
        });
        setIsLoaderVisible(false);
        form.setFieldsValue({
          searchtext: '',
        });
      }
    });
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteSingleTestKit(id.toString());
      },
    });
  };

  const deleteSingleTestKit = (Ids) => {
    commonService.httpGet(`${urlConstant.TestKit.DeleteTestKit}?Ids=${Ids}`, true).then((response) => {
      if (response.status === dataConstant.APIStatus.Success) {
        notification.success({
          message: 'Play Kit',
          description: response.message,
        });
        getTestKitData();
      } else if (response.status === dataConstant.APIStatus.Failure) {
        notification.warning({
          message: 'Play Kit',
          description: response.error,
        });
      }
    });
  };

  const redirectToAddTestKit = () => {
    history.push({
      pathname: `/admin/manageTestkit`,
    });
  };

  const editTestKit = (id, name) => {
    history.push({
      pathname: `/admin/manageTestkit/${id}`,
      state: name,
    });
  };

  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: true,
      align: 'center',
      width: 30,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Edit">
            <Button
              icon={<EditOutlined />}
              className="mr-1"
              size="small"
              onClick={() => editTestKit(record.id, record.name)}
            />
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 400,
      dataType: 'StringContains',
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      align: 'center',
      width: 30,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Delete">
            <Button icon={<DeleteOutlined />} size="small" onClick={() => confirmDelete(record.id)} />
          </Tooltip>
        </span>
      ),
    },
  ];

  const Refresh = (current) => {
    const initRequestParams = {
      pageNumber: 1,
      rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage,
      filter: {
        Name: '',
      },
      sortColumns: dataConstant.tableRequest.sortColumns,
    };
    setSearchText('');
    form.resetFields();
    getTestKitData(initRequestParams);
  };

  return (
    <div>
      <div className="page-header">
        <h1>Play Kit</h1>
        <Form form={form}>
          <Row justify="end">
            <Form.Item id="data" label="Search" name="searchtext" className="col-12 col-md-6">
              <div className="d-flex">
                <Input placeholder="Search" onChange={(e) => setSearchText(e.target.value)} />
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={() => setSearchText('')}
                  htmlType="submit"
                  type="primary"
                />
              </div>
            </Form.Item>
          </Row>
        </Form>
      </div>
      <TableComponent
        ref={child}
        rowKey="id"
        columns={columns}
        dataSource={testKitData.testKitData}
        getGridData={getTestKitData}
        totalCount={testKitData.testKitTotalCount}
        scroll={{ x: 'max-content' }}
        loading={isLoaderVisible}
        refresh={(current) => Refresh(current)}
        isShowAddButton
        addCallbackMethod={redirectToAddTestKit}
        pageNumber={tableRequestParam.pageNumber}
      />
    </div>
  );
}
