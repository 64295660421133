import React, { useState, useMemo, useEffect } from 'react';
import { Tooltip, Button, Form, Row, Input, Modal, notification } from 'antd';
import { debounce } from 'lodash';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CloseCircleOutlined,
  UnlockOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import TableComponent from '../../../TableComponent';
import dataConstant from '../../../constants/dataConstant';
import commonService from '../../../services/commonService';
import urlConstant from '../../../constants/urlConstant';
import messageConstant from '../../../constants/messageConstant';
let userRole;
let userId = '';

function Client() {
  const child = React.createRef();
  const history = useHistory();
  const [filterform] = Form.useForm();
  const [resetForm] = Form.useForm();
  const [organizationData, setOrganizationData] = useState({
    organizationList: '',
    organizationTotalcount: '',
  });
  const debounceFilterData = useMemo(() => debounce(applyFilter, 1000), []);
  const [showPassWord, setShowPassWord] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [passwordId, setPasswordId] = useState();
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [tableRequestParam, setTableRequestParam] = useState({
    pageNumber: dataConstant.tableRequest.pageNumber,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
      ? JSON.parse(localStorage.getItem('RowsPerPage'))
      : dataConstant.tableRequest.rowsPerPage,
    filter: {
      Name: '',
      Email: '',
      Phone: '',
      Website: '',
      Address: '',
      IsActive: '',
    },
    OrganizationContactPerson: '',
    OrganizationId: 0,
    sortColumns: dataConstant.tableRequest.sortColumns,
  });

  // get organization list data
  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
  }, []);

  // fileter data with search text
  useEffect(() => {
    if (searchText !== '') {
      debounceFilterData(searchText);
    } else {
      gridRefresh();
    }
  }, [searchText]);

  // refresh table data with table request param
  const gridRefresh = () => {
    filterform.resetFields();
    setTableRequestParam({
      pageNumber: dataConstant.tableRequest.pageNumber,
      rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage,
      filter: {
        Name: '',
        Email: '',
        Phone: '',
        Website: '',
        Address: '',
      },
      sortColumns: dataConstant.tableRequest.sortColumns,
    });
    tableRequestParam.filter.Name = '';
    tableRequestParam.filter.Email = '';
    tableRequestParam.filter.Phone = '';
    tableRequestParam.filter.Website = '';
    tableRequestParam.filter.Address = '';
    getOrganizationList(tableRequestParam);
  };

  // get Filter data with search text
  function applyFilter(SearchValue) {
    if (SearchValue !== '') {
      const filter = {};
      filter.Name = SearchValue;
      filter.ContactPerson = SearchValue;
      filter.Email = SearchValue;
      filter.Phone = SearchValue;
      filter.Website = SearchValue;
      filter.Address = SearchValue;

      const searchModelData = tableRequestParam;
      searchModelData.filter = filter;
      searchModelData.pageNumber = dataConstant.tableRequest.pageNumber;
      searchModelData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage;
      getOrganizationList(tableRequestParam);
    }
  }

  // method for get organization list data from server with table request param
  const getOrganizationList = (requestObject) => {
    setIsLoaderVisible(true);
    const modelData = tableRequestParam;

    if (requestObject) {
      modelData.pageNumber = requestObject.pageNumber;
      modelData.rowsPerPage = requestObject.rowsPerPage;
      modelData.sortColumns = requestObject.sortColumns;
      modelData.filter = requestObject.filter ? requestObject.filter : tableRequestParam.filter;
    }
    commonService.httpPost(urlConstant.Organization.GetOrganization, modelData, true, true).then((response) => {
      if (response && response.data) {
        setOrganizationData({
          organizationList: response.data.list,
          organizationTotalcount: response.data.totalCount,
        });
        setIsLoaderVisible(false);
      }
    });
  };

  // method redirect to edit organization page with passing organization id as param
  const editOrganizationById = (id) => {
    history.push({
      pathname: `/admin/manageOrganization/${id}`,
    });
  };

  // method for Add new organization in server from plus icon
  const redirectToAddOrganization = () => {
    history.push({
      pathname: `/admin/manageOrganization`,
    });
  };

  // method for refresh table data with table request param
  const Refresh = () => {
    let refreshTableData = tableRequestParam;
    (refreshTableData.pageNumber = dataConstant.tableRequest.pageNumber),
      (refreshTableData.rowsPerPage = JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.tableRequest.rowsPerPage),
      (refreshTableData.filter = {
        Name: '',
        Email: '',
        Phone: '',
        Website: '',
        Address: '',
        IsActive: '',
      }),
      (refreshTableData.OrganizationContactPerson = ''),
      (refreshTableData.OrganizationId = 0),
      (refreshTableData.sortColumns = dataConstant.tableRequest.sortColumns),
      setSearchText('');
    filterform.resetFields();
    getOrganizationList(refreshTableData);
  };

  // Show model for Delete organization
  const confirmDelete = (id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: (
        <>
          {messageConstant.OrganizationDeleteConfirmation} <br />
          {messageConstant.SureMessage}
        </>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteSingleOrganization(id.toString());
      },
    });
  };

  // method for Reset password in server with User id
  const resetPassword = (id) => {
    userId = id;
    setResetPasswordModal(true);
  };

  // Method to close reset password model
  const closeResetPasswordModal = () => {
    setResetPasswordModal(false);
    resetForm.resetFields();
  };

  // method to call Api for reset password and save in server
  const saveResetPassword = () => {
    resetForm.validateFields().then(async (resetPasswordData) => {
      resetPasswordData.UserId = userId;
      if (resetPasswordData) {
        commonService.httpPost(urlConstant.User.ResetPassword, resetPasswordData, true).then((response) => {
          if (response.status === dataConstant.APIStatus.Success) {
            notification.success({
              message: 'Password Changed',
              description: response.message,
            });
            closeResetPasswordModal();
            gridRefresh();
          } else if (response.status === dataConstant.APIStatus.Failure) {
            notification.warning({
              message: 'Password Changed',
              description: response.message,
            });
          }
        });
        resetForm.resetFields();
      }
    });
  };

  // method for delete organization in server with organization id
  const deleteSingleOrganization = (Id) => {
    commonService
      .httpGet(`${urlConstant.Organization.DeleteOrganizationById}?Id=${parseInt(Id)}`, true)
      .then((response) => {
        if (response.status === dataConstant.APIStatus.Success) {
          notification.success({
            message: 'Organization',
            description: response.message,
          });
          getOrganizationList(tableRequestParam);
        } else if (response.status === dataConstant.APIStatus.Failure) {
          notification.warning({
            message: 'Organization',
            description: response.error,
          });
        }
      });
  };

  // set Table data
  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: true,
      align: 'center',
      width: 100,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Edit">
            <Button
              icon={<EditOutlined />}
              className="mr-1"
              size="small"
              onClick={() => editOrganizationById(record.organizationId, record.organizationName)}
            />
          </Tooltip>

          <Tooltip placement="bottom" title="Reset organization user password">
            <Button
              icon={<UnlockOutlined />}
              className="mr-1"
              size="small"
              onClick={() => resetPassword(record.userId)}
            />
          </Tooltip>
        </span>
      ),
    },
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
      // sorter: true,
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Contact Person',
      dataIndex: 'organizationContactPerson',
      key: 'organizationContactPerson',
      // sorter: true,
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'User Email',
      dataIndex: 'userEmail',
      key: 'userEmail',
      // sorter: true,
      width: 200,
      dataType: 'StringContains',
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      width: 150,
      dataType: 'StringContains',
      render: (data, record) => {
        return (
          <Row justify="space-between">
            {passwordId == record.organizationId && showPassWord ? (
              <span>{data}</span>
            ) : (
              <span>{'*'.repeat(data.length)}</span>
            )}
            &nbsp;&nbsp;
            <Tooltip
              placement="bottom"
              title={passwordId == record.organizationId && showPassWord ? 'Hide Password' : 'Show Password'}
            >
              <Button
                icon={passwordId == record.organizationId && showPassWord ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                className="mr-1"
                size="small"
                onClick={() => {
                  setPasswordId(record.organizationId);
                  setShowPassWord(!showPassWord);
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: 'Organization Status',
      dataIndex: 'organizationIsActive',
      key: 'organizationIsActive',
      align: 'center',
      //  sorter: true,
      width: 160,
      dataType: 'StringContains',
      render: (data) =>
        data === true ? (
          <Button type="primary">Active</Button>
        ) : (
          <Button type="warning" danger>
            Inactive
          </Button>
        ),
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      fixed: 'right',
      align: 'center',
      width: 30,
      render: (text, record) => (
        <span>
          <Tooltip placement="bottom" title="Delete">
            <Button onClick={() => confirmDelete(record.organizationId)} icon={<DeleteOutlined />} size="small" />
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="page-header">
        <h1>Client</h1>
        <Form form={filterform}>
          <Row justify="end">
            <Form.Item id="data" label="Search" name="searchtext" className="col-12 col-md-6">
              <div className="d-flex">
                <Input placeholder="Search by Oraganization Details" onChange={(e) => setSearchText(e.target.value)} />
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={() => setSearchText('')}
                  htmlType="submit"
                  type="primary"
                />
              </div>
            </Form.Item>
          </Row>
        </Form>
      </div>

      <Modal
        visible={resetPasswordModal}
        title={`Reset Password`}
        centered
        okText="Reset"
        width={500}
        onOk={saveResetPassword}
        onCancel={closeResetPasswordModal}
      >
        <Form form={resetForm} className="cm-country-form">
          <Form.Item id="id" name="id" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item
            id="Password"
            name="Password"
            label="New Password"
            rules={[
              { required: true, message: dataConstant.resetPassword.newPasswordRequired },
              {
                pattern: dataConstant.passwordPattern,
                message: dataConstant.resetPassword.newPasswordMessage,
              },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['Password']}
            rules={[
              {
                required: true,
                message: `${messageConstant.Required} Confirm Password`,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('Password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error(messageConstant.ConfirmPassword));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>

      <TableComponent
        ref={child}
        rowKey="organizationName"
        columns={columns}
        dataSource={organizationData.organizationList}
        getGridData={getOrganizationList}
        totalCount={organizationData.organizationTotalcount}
        scroll={{ x: 'max-content' }}
        loading={isLoaderVisible}
        refresh={(current) => Refresh(current)}
        isShowAddButton
        addCallbackMethod={redirectToAddOrganization}
        pageNumber={tableRequestParam.pageNumber}
      />
    </>
  );
}

export default Client;
