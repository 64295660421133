/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Spin, notification } from 'antd';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { httpGet, httpPost } from '../../services/commonService';
import urlConstant from '../../constants/urlConstant';
import dataConstant from '../../constants/dataConstant';
import messageConstant from '../../constants/messageConstant';

const Scent = ({
  match: {
    params: { id },
  },
}) => {
  const history = useHistory();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [smellIntensityValue, setSmellIntensityValue] = useState();
  const [scentCardList, setScentCardList] = useState([]);
  const [uniqueScentCardList, setUniqueScentCardList] = useState([]);
  const [selectedScentCard, setSelectedScentCard] = useState(0);
  const [scentCardOptions, setScentCardOptions] = useState();
  const [selectedCardAnswer, setSelectedCardAnswer] = useState();
  const [finalTestResult, setFinalTestResult] = useState({
    TestKitId: null,
    Latitude: null,
    Longitude: null,
    // UserName: null,
    FirstName: null,
    LastName: null,
    OrganizationId: null,
    TestResultDetails: [],
  });

  const [imageURL, setImageURL] = useState([]);
  const imageRef = React.useRef(imageURL && imageURL.map(() => React.createRef()));

  /** Get scent card detail on page load */
  useEffect(() => {
    window.scrollTo(0, 0);
    const safePassData = JSON.parse(localStorage.getItem('safePassData'));
    if (!safePassData || !safePassData.FirstName || !safePassData.LastName) {
      const userData = {};
      userData.FirstName = 'Test1';
      userData.LastName = 'Test1';
      userData.isAddOrganizationChecked = false;
      localStorage.setItem('safePassData', JSON.stringify(userData));
    }
    getScentCardDetails();
  }, []);

  /** Get Scent Card Details and Options List */
  const getScentCardDetails = () => {
    setIsLoaderVisible(true);
    httpGet(`${urlConstant.ScentCard.GetSentCardDetails}${id}`)
      .then((response) => {
        if (response) {
          if (response.status === dataConstant.APIStatus.Success) {
            const sortScentCardList = _.sortBy(response.data, 'scentCardSortOrder');
            const uniqueScentCardList = _.sortBy(_.uniqBy(response.data, `scentCardId`), 'scentCardSortOrder');
            setScentCardList(sortScentCardList);
            setUniqueScentCardList(uniqueScentCardList);
            setSmellIntensityValue(1);
            setSelectedScentCard(1);
            setIsLoaderVisible(false);
          } else if (response.status === dataConstant.APIStatus.Failure) {
            notification.error({
              message: dataConstant.NotificationType.Error,
              description: messageConstant.InvalidQRCode,
            });
            history.push({ pathname: '/scanQRCode' });
            setIsLoaderVisible(false);
          } else {
            uncaughtErrorHandler();
          }
        } else {
          uncaughtErrorHandler();
        }
      })
      .catch((error) => {
        uncaughtErrorHandler();
      });
  };

  /** Filter selected card options from scentCardList */
  useEffect(() => {
    if (scentCardList && scentCardList.length > 0 && selectedScentCard > 0) {
      const cardOptions = scentCardList.filter(
        (item) => item.scentCardSortOrder === uniqueScentCardList[selectedScentCard - 1].scentCardSortOrder
      );

      let sortedList = _.sortBy(cardOptions, 'scentCardOptionsSortOrder');

      /** Added upcoming list to imageURL to create ref fot total elements within this array. */
      setImageURL(sortedList);

      setScentCardOptions(sortedList);
    }
  }, [scentCardList, selectedScentCard]);

  /** If any uncaught error throws then redirect to home page */
  const uncaughtErrorHandler = () => {
    setIsLoaderVisible(false);
    notification.error({
      message: dataConstant.NotificationType.Error,
      description: messageConstant.SomethingWentWrong,
    });
    history.push({ pathname: '/home' });
  };

  /** Close Smell Intensity Popup and set default Mild as active */
  const openSmellIntensityPopup = (selectedCardOption) => {
    setSelectedCardAnswer(selectedCardOption.scentCardOptionDetailId);
  };

  useEffect(() => {
    if (selectedCardAnswer) {
      onChangeSmellIntensityValue();
    }
  }, [selectedCardAnswer]);

  /** Close modal popup when selected smell intensity value saved in state variable */
  const onChangeSmellIntensityValue = () => {
    scentCardOptions.map((item) => {
      imageRef.current.forEach((ref) => {
        if (ref.current.id == item.scentCardOptionsId.toString()) {
          ref.current.removeAttribute('src');
        }
      });
    });
    const scentCardId = _.first(scentCardOptions).scentCardId;

    let result = Object.assign({}, finalTestResult);

    /** IF all step completed then add detail of test kit, lat and long */
    if (selectedScentCard === uniqueScentCardList.length) {
      let TestKitId = 0;
      if (scentCardList && scentCardList.length > 0) {
        TestKitId = _.first(scentCardList).testKitId;
      }

      const safePassData = localStorage.getItem('safePassData');
      let userData = safePassData ? JSON.parse(safePassData) : {};
      result = {
        ...result,
        TestKitId: TestKitId,
        OrganizationId: userData ? userData.organizationId : null,
        Latitude: null,
        Longitude: null,
        // UserName: userData.name,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
      };
    }

    /** Update test result object with selected card, selected answer and smell intensity detail */
    setFinalTestResult({
      ...result,
      TestResultDetails: [
        ...result.TestResultDetails,
        {
          ScentCardsId: scentCardId,
          AnswerId: selectedCardAnswer,
          SmellIntensity: smellIntensityValue,
        },
      ],
    });

    /** If it is not last card then increment card with one to move next card */
    if (selectedScentCard < uniqueScentCardList.length) {
      setSelectedScentCard(selectedScentCard + 1);
    }
    setSelectedCardAnswer();
  };

  /** Once all step completed and state variable updated with last result then call save result function */
  useEffect(() => {
    if (
      uniqueScentCardList.length !== 0 &&
      selectedScentCard === uniqueScentCardList.length &&
      finalTestResult.TestResultDetails &&
      finalTestResult.TestResultDetails.length === uniqueScentCardList.length
    ) {
      saveFinalResult();
    }
  }, [finalTestResult]);

  /** Save result of test into DB */
  const saveFinalResult = () => {
    setIsLoaderVisible(true);
    httpPost(urlConstant.TestResult.SaveTestResult, finalTestResult)
      .then((response) => {
        if (response) {
          if (response.status === dataConstant.APIStatus.Success) {
            localStorage.setItem('testResultData', JSON.stringify(response.data));
            if (response.data.resultStatus === 'Pass') {
              history.push({ pathname: `/pass` });
            } else {
              history.push({ pathname: `/fail` });
            }
            setIsLoaderVisible(false);
          } else if (response.status === dataConstant.APIStatus.Failure) {
            setIsLoaderVisible(false);
            notification.error({
              message: dataConstant.NotificationType.Error,
              description: messageConstant.InvalidQRCode,
            });
            history.push({ pathname: '/scanQRCode' });
          } else {
            uncaughtErrorHandler();
          }
        } else {
          uncaughtErrorHandler();
        }
      })
      .catch((error) => {
        uncaughtErrorHandler();
      });
  };

  return (
    <>
      <Spin tip="Loading..." size="large" spinning={isLoaderVisible}>
        <section className="cm-main-content-wrapper">
          <div className="container d-flex flex-wrap align-items-center justify-content-between h-100">
            <div className="cm-scent-wrapper d-flex justify-content-between flex-wrap order-1 order-lg-0">
              {scentCardOptions && scentCardOptions.length > 0 && (
                <>
                  {scentCardOptions.map((item, index) => (
                    <React.Fragment key={item.scentCardOptionsId}>
                      <div
                        className="cm-scent-item"
                        onClick={() => openSmellIntensityPopup(item)}
                        id={item.scentCardOptionDetailId}
                        title={item.scentCardOptionsName}
                        key={item.scentCardOptionDetailId}
                      >
                        <img
                          id={item.scentCardOptionsId}
                          ref={imageRef.current[index]}
                          src={item.storedPath}
                          alt={item.scentCardOptionsName}
                          key={item.scentCardOptionsId}
                        />
                        {/* {<h6>{item.scentCardOptionsName}</h6>} */}
                      </div>
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>
            <div className="cm-cartoon cm-whatdoyousmell-cartoon">
              <img className="d-none d-lg-block" src="resources/images/character-whatdoyousmell.png" alt="cartoon" />
              <img className="d-lg-none" src="resources/images/character-whatdoyousmell-1.png" alt="cartoon" />
            </div>
          </div>
        </section>
        <section className="cm-step-wrapper">
          <ul className="steps">
            {uniqueScentCardList && uniqueScentCardList.length > 1 && (
              <>
                {uniqueScentCardList.map((item, index) => (
                  <React.Fragment key={index}>
                    {index != 0 && (
                      <li className="step_arrow" key={item.scentCardOptionsId + 5}>
                        <img src="/resources/images/arrow.png" alt="arrow" />
                      </li>
                    )}
                    <li
                      className={selectedScentCard === index + 1 ? 'step active' : 'step'}
                      key={item.scentCardId + 15}
                    >
                      <span>SCENT</span>
                      <span className="count">{index + 1}</span>
                    </li>
                  </React.Fragment>
                ))}
              </>
            )}
          </ul>
          <div className="cm-action-arrow">
            <span className="pre-arrow">
              <img src="resources/images/prev-slide.png" alt="prev-slide" />
            </span>
            <span className="next-arrow d-none">
              <img src="resources/images/next-slide.png" alt="next-slide" />
            </span>
          </div>
        </section>
        <section className="cm-bottom-content pb-md-0">
          <img className="img-fluid cm-chain" src="resources/images/chain.png" alt="chain" />
          <div className="container d-flex align-items-center justify-content-between">
            <img className="cm-star left" src="resources/images/stars.png" alt="stars" />
            <ul className="cm-steps-wrapper">
              <li>
                <img src="resources/images/step-1.png" alt="step1" />
              </li>
              <li>
                <img src="resources/images/step-2.png" alt="step2" />
              </li>
              <li>
                <img src="resources/images/step-3.png" alt="step3" />
              </li>
            </ul>
            <img className="cm-star right" src="resources/images/stars.png" alt="stars" />
          </div>
        </section>
      </Spin>
    </>
  );
};
export default Scent;
