/* eslint-disable react/no-deprecated */
import React from 'react';
import { Table, Tooltip, Button, Input } from 'antd';
import 'antd/dist/antd.css';
import { SearchOutlined, ReloadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import _ from 'underscore';
import dataConstant from './constants/dataConstant';

class CustomeTableComponant extends React.Component {
  state = {
    request: {
      pageNumber: 1,
      rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.pagination.defaultPageSize,
      searchColumns: [],
      sortColumns: dataConstant.sortColumnsForCity,
    },
    pagination: {
      current: 1,
      defaultPageSize: JSON.parse(localStorage.getItem('RowsPerPage'))
        ? JSON.parse(localStorage.getItem('RowsPerPage'))
        : dataConstant.pagination.defaultPageSize,
      showSizeChanger: dataConstant.pagination.showSizeChanger,
      pageSizeOptions: dataConstant.pagination.pageSizeOptions,
      total: 0,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
    selectedRowKeys: [],
    isMultipleDelete: false,
    columns: [],
  };

  componentWillReceiveProps = (newProps) => {
    const { totalCount, pageNumber } = this.props;
    const { request, pagination } = this.state;
    this.setState({
      request: {
        ...request,
        pageNumber,
      },
      pagination: {
        ...pagination,
        current: pageNumber,
      },
    });
    const { columns } = newProps;
    if (totalCount !== newProps.totalCount) {
      this.setState({
        pagination: {
          ...pagination,
          total: newProps.totalCount,
        },
      });
    }
    if (columns.length > 0) {
      _.each(columns, (item) => {
        if (item.isSearchable) {
          const { filterIcon, filterDropdown } = {
            ...this.getColumnSearchProps(item.dataIndex, item.dataType, item.title),
          };
          item.filterIcon = filterIcon;
          item.filterDropdown = filterDropdown;
        }
        if (item.isQuickSearch) {
          const { quickSearch } = {
            ...this.getQuickSearchProps(item.dataIndex, item.dataType, item.title),
          };
          item.render = quickSearch;
        }
      });
      this.setState({
        columns: _.filter(columns, (item) => {
          return item.isHidden !== true;
        }),
      });
    }
  };

  getGridData = () => {
    const { request } = this.state;
    const { getGridData } = this.props;
    getGridData(request);
  };

  handleTableChange = (pager, filters, sorter) => {
    const { pagination, request } = this.state;
    pagination.current = pager.current;
    const { pageSize, current } = pager;
    const { searchColumns } = request;

    this.setState(
      {
        pagination,
        request: {
          rowsPerPage: pageSize,
          pageNumber: current,
          searchColumns,
          sortColumns: {
            sortColumnName: sorter.field || 'city',
            sortOrder: (sorter.order === 'ascend' ? 'asc' : '') || (sorter.order === 'descend' ? 'desc' : '') || 'asc',
          },
        },
        selectedRowKeys: [],
        isMultipleDelete: false,
      },
      () => {
        const { onSelectChange } = this.props;
        this.getGridData();
        if (onSelectChange) {
          onSelectChange([], []);
        }
      }
    );
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    const { onSelectChange } = this.props;
    if (selectedRowKeys.length > 0) {
      this.setState({
        selectedRowKeys,
        isMultipleDelete: true,
      });
    } else {
      this.setState({
        selectedRowKeys: [],
        isMultipleDelete: false,
      });
    }
    if (onSelectChange) {
      onSelectChange(selectedRowKeys, selectedRows);
    }
  };

  handleSearch = (selectedKeys, dataIndex, dataType, isQuickSearch) => {
    const { request } = this.state;
    let { searchColumns } = request;

    const searchobject = _.filter(searchColumns, (item) => {
      return item.isQuickSearch !== true;
    });

    searchColumns = searchobject;

    const objSearchData = {
      searchedColumn: dataIndex,
      searchText: !isQuickSearch ? selectedKeys[0] && selectedKeys[0].trim() : selectedKeys,
      ColumnDataType: dataType,
      isQuickSearched: isQuickSearch,
    };
    searchColumns.push(objSearchData);

    this.setState(
      {
        request: { ...request, pageNumber: 1, searchColumns },
      },
      () => {
        this.getGridData();
      }
    );
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    const { request, pagination } = this.state;
    const { searchColumns } = request;
    searchColumns.splice(
      searchColumns.findIndex((x) => x.searchedColumn === dataIndex),
      1
    );
    this.setState(
      {
        pagination: { ...pagination, current: 1 },
        request: { ...request, pageNumber: 1, searchColumns },
      },
      () => {
        this.getGridData();
      }
    );
  };

  getColumnSearchProps = (dataIndex, dataType, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(input) => input && input.focus()}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex, dataType, false)}
          style={{ width: 199, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, dataIndex, dataType, false)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (
      <SearchOutlined
        style={{
          color: this.searchedColumnIndex(dataIndex) ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  getQuickSearchProps = (dataIndex, dataType) => ({
    quickSearch: (text) => (
      <a
        className="quick_link"
        href={() => false}
        onClick={() => {
          this.handleSearch(text, dataIndex, dataType, true);
        }}
      >
        {' '}
        {text}{' '}
      </a>
    ),
  });

  searchedColumnIndex = (dataIndex) => {
    const { request } = this.state;
    const { searchColumns } = request;
    const index = searchColumns.findIndex((x) => x.searchedColumn === dataIndex);
    let flag = false;
    if (index >= 0) {
      flag = true;
    }
    return flag;
  };

  refresh = () => {
    const { refresh } = this.props;
    const { pagination, request } = this.state;
    this.setState(
      {
        pagination: { ...pagination, current: 1 },
        request: {
          ...request,
          pageNumber: 1,
          searchColumns: [],
          sortColumns: dataConstant.sortColumnsForCity,
        },
        selectedRowKeys: [],
        isMultipleDelete: false,
      },
      () => {
        if (refresh) {
          refresh();
        }
      }
    );
  };

  pageRefresh = () => {
    const { refresh } = this.props;
    const { pagination, request } = this.state;
    this.setState(
      {
        pagination: { ...pagination, current: 1 },
        request: {
          ...request,
          PageNumber: 1,
          searchColumns: [],
          SortColumns: {
            SortColumnName: '',
            SortOrder: '',
          },
        },
        selectedRowKeys: [],
        IsMultipleDelete: false,
      },
      () => {
        if (refresh) {
          refresh();
        }
      }
    );
  };

  addCallbackMethod = () => {
    const { addCallbackMethod } = this.props;
    addCallbackMethod();
  };

  deleteCallbackMethod = () => {
    const { deleteCallbackMethod } = this.props;
    const { selectedRowKeys, isMultipleDelete } = this.state;
    deleteCallbackMethod(selectedRowKeys, isMultipleDelete);
  };

  render() {
    const {
      rowKey,
      dataSource,
      loading,
      scroll,
      isShowAddButton,
      isShowMultiDelete,
      enableRowSelection,
      expandedRowRender,
    } = this.props;

    const { pagination, selectedRowKeys, isMultipleDelete, columns } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <Table
        rowKey={(record) => record[rowKey]}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        loading={loading}
        onChange={this.handleTableChange}
        rowSelection={enableRowSelection ? rowSelection : undefined}
        size="middle"
        scroll={scroll}
        bordered
        expandedRowRender={expandedRowRender}
        title={() => (
          <div className="text-right">
            {isShowMultiDelete && isMultipleDelete && (
              <Tooltip placement="bottom" title="Delete">
                <Button
                  icon={<DeleteOutlined />}
                  className="ant-btn mr-1 ant-btn-sm ant-btn-icon-only"
                  onClick={() => this.deleteCallbackMethod()}
                ></Button>
              </Tooltip>
            )}
            <Tooltip placement="bottom" title="Refresh">
              <Button className="ant-btn mr-1 ant-btn-sm ant-btn-icon-only" onClick={this.pageRefresh}>
                <ReloadOutlined />
                <i className="fa fa-refresh" />
              </Button>
            </Tooltip>
            {isShowAddButton && (
              <Tooltip placement="bottom" title="Add">
                <Button
                  icon={<PlusOutlined />}
                  className="ant-btn mr-1 ant-btn-sm ant-btn-icon-only"
                  onClick={this.addCallbackMethod}
                >
                  <i className="fa fa-plus" />
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      />
    );
  }
}

export default CustomeTableComponant;
