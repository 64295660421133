import React from 'react';

const FrontendFooter = () => {
  return (
    <footer>
      <p>© MGA Entertainment, Inc.</p>
      <p>
        L.O.L. SURPRISE!™ is a trademark of MGA in the U.S. and other countries. All logos, names, characters,
        likenesses, images, slogans, and packaging appearance are the property of MGA. Used under license by YOYO WORLD.
      </p>
    </footer>
  );
};

export default FrontendFooter;
