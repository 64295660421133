import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Radio, Progress } from 'antd';
import dataConstant from '../../constants/dataConstant';
import urlConstant from '../../constants/urlConstant';
import { httpPost } from '../../services/commonService';

let testResultId = '';
const QuestionPage = () => {
  const [checkTestStatus, setCheckTestStatus] = useState(false);
  const [index, setIndex] = useState(0);
  const [answer, setAnswer] = useState({
    TestResultId: '',
    QuestionnaireListDetails: [],
  });
  const [value, setValue] = useState();
  const history = useHistory();

  /** Get testResultId from localStorage and check test status on page load */
  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem('testResultData')) {
      const objTestResult = JSON.parse(localStorage.getItem('testResultData'));
      if (objTestResult && objTestResult.testResultId) {
        testResultId = objTestResult.testResultId;
        checkStatus();
      } else history.push('/home');
    }
  }, []);

  /** Check test status based on testResultId */
  const checkStatus = () => {
    httpPost(urlConstant.TestResult.GetTestStatus + testResultId)
      .then((response) => {
        setCheckTestStatus(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /** save Questionnaire Details and redirect to 'additionalQuestionDetails' page on success */
  const saveQuestionnaireDetails = () => {
    if (checkTestStatus != true) {
      const data = {
        TestResultId: answer.TestResultId,
        QuestionnaireListDetails: answer.QuestionnaireListDetails,
      };
      httpPost(urlConstant.TestResult.SaveQuestionnaireResult, data)
        .then((response) => {
          if (response && response.status && response.status.toLowerCase() === 'success') {
            history.push({
              pathname: '/additionalQuestionDetails',
              state: {
                questionDetails: answer,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const numberOfQuestions = dataConstant.Questions.length;

  /** create answer array and update index based on answer selection */
  const increaseIndex = (val) => {
    if (index < numberOfQuestions - 1) {
      setAnswer({
        TestResultId: testResultId,
        QuestionnaireListDetails: [
          ...answer.QuestionnaireListDetails,
          {
            Question: dataConstant.Questions[index],
            Answer: val.target.value,
          },
        ],
      });
      setIndex(index + 1);
    } else {
      answer.QuestionnaireListDetails.push({
        Question: dataConstant.Questions[index],
        Answer: val.target.value,
      });
      saveQuestionnaireDetails();
    }
    setValue();
  };

  return (
    <div className="questions_page">
      <header className="d-flex align-items-center justify-content-between">
        <Link className="logo" to="/home">
          <img src="/resources/images/logo-white.png" alt="logo" />
        </Link>
      </header>
      {checkTestStatus === true ? (
        <div className="questions_body">
          <h1 className="ctnu-text">Questionnaire is already completed</h1>
          <button className="btn-ctnu" onClick={() => history.push('/home')}>
            Continue
          </button>
        </div>
      ) : (
        <div className="questions_wrapper">
          <h1>Additional Questions</h1>
          <div className="progress_wrapper text-center">
            <Progress
              type="circle"
              percent={((index + 1) / numberOfQuestions) * 100}
              format={(percent) => `0${index + 1}`}
            />
          </div>
          <h4>{dataConstant.Questions[index]}</h4>
          <Radio.Group className="action_btns" buttonStyle="solid" onChange={increaseIndex} value={value}>
            <Radio.Button value="Yes">Yes</Radio.Button>
            <Radio.Button value="No">No</Radio.Button>
          </Radio.Group>
        </div>
      )}
      <footer>
        <p>
          <b>{dataConstant.footerMessage}</b>
        </p>
      </footer>
    </div>
  );
};

export default QuestionPage;
