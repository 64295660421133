import * as React from 'react';

export default class FullLayout extends React.PureComponent<{}, { children?: React.ReactNode }> {
  public render() {
    return (
      <React.Fragment>
        <div>{this.props.children}</div>
      </React.Fragment>
    );
  }
}
