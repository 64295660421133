import React, { useEffect, useState } from 'react';
import dataConstant from '../../../constants/dataConstant';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';

const DashBoard = () => {
  const [widgetData, setwidgetData] = useState({
    totalTestCount: 0,
    failCount: 0,
    passCount: 0,
    testKitCount: 0,
  });

  useEffect(() => {
    getTestResultCounts();
  }, []);

  const getTestResultCounts = () => {
    commonService.httpGet(urlConstant.TestResult.GetTestResultCounts, true).then((response) => {
      if (response.status === dataConstant.APIStatus.Success) {
        setwidgetData({
          totalTestCount: response.data.totalTestCount,
          failCount: response.data.failCount,
          passCount: response.data.passCount,
          testKitCount: response.data.testKitCount,
        });
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-3">
          <div className="card bg-light mb-3 custom-card-width mx-sm-0 mx-auto">
            <div className="card-header">Total Test</div>
            <div className="card-body">
              <p className="card-text">{widgetData.totalTestCount}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card bg-light mb-3 custom-card-width mx-sm-0 mx-auto">
            <div className="card-header">Total Pass</div>
            <div className="card-body">
              <p className="card-text">{widgetData.passCount}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card bg-light mb-3 custom-card-width mx-sm-0 mx-auto">
            <div className="card-header">Total Fail</div>
            <div className="card-body">
              <p className="card-text">{widgetData.failCount}</p>
            </div>
          </div>
        </div>
        <div className=" col-sm-3">
          <div className="card bg-light mb-3 custom-card-width mx-sm-0 mx-auto">
            <div className="card-header">Total Test Kit</div>
            <div className="card-body">
              <p className="card-text">{widgetData.testKitCount}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
