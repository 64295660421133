/* eslint-disable no-empty */
import React from 'react';
import { Button, Card, Form, notification, Input } from 'antd';
import { useHistory } from 'react-router';
import urlConstant from '../../constants/urlConstant';
import commonService from '../../services/commonService';
import dataConstant from '../../constants/dataConstant';
import Schema from 'async-validator';
import messageConstant from '../../constants/messageConstant';

const Login = () => {
  Schema.warning = function () {};
  const [form] = Form.useForm();
  const history = useHistory();
  window.scrollTo(0, 0);

  const handleLogin = async (formData) => {
    form.validateFields().then(async (formData) => {
      try {
        await commonService.httpPost(urlConstant.User.Authenticate, formData).then(
          (response) => {
            if (response.status === dataConstant.APIStatus.Success) {
              if (response.data.token) {
                localStorage.setItem('user', JSON.stringify(response.data));
              }
              history.push('/admin/dashboard');
            }
            if (response.status === dataConstant.APIStatus.Failure) {
              notification.warning({
                message: `Login`,
                description: response.message,
              });
            }
          },
          (error) => {}
        );
      } catch (err) {}
    });
  };

  form.setFieldsValue({
    username: '',
    password: '',
  });

  return (
    <div className="login_wrapper">
      <Card className="rounded-0 mb-0 border-0 text-center">
        <div className="logo_wrapper">
          <img className="logo" src="/resources/images/favicon.png" alt="logo" />
        </div>
        <Form form={form} onFinish={(e) => handleLogin(e)}>
          <Form.Item
            required={false}
            id="Email"
            type="email"
            name="Email"
            label="User Name"
            className="form-label-group position-relative has-icon-left"
            rules={[
              {
                required: true,
                message: messageConstant.Email.emailRequired,
              },
              {
                type: 'email',
                message: messageConstant.Email.emailValid,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            required={false}
            label="Password"
            id="password"
            name="password"
            className="form-label-group position-relative has-icon-left"
            rules={[
              {
                required: true,
                message: messageConstant.manageUser.passwordRequired,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
