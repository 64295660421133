import React, { useState, useEffect } from 'react';
import Camera from '../../Test/Camera';
import { useHistory } from 'react-router';
import { Button, Form, Modal, notification } from 'antd';
import dataConstant from '../../constants/dataConstant';

const ScanQRCode = () => {
  const history = useHistory();
  const [camera, setCamera] = useState(false);
  const [secretCode, setSecretCode] = useState('');
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    // const safePassData = localStorage.getItem('safePassData');
    // const userData = safePassData ? JSON.parse(safePassData) : {};
    // userData.FirstName = 'Test1';
    // userData.LastName = 'Test1';
    // userData.isAddOrganizationChecked = false;
    // localStorage.setItem('safePassData', JSON.stringify(userData));
    /** Check localStorage data (name) on page load */
    if (!localStorage.getItem('safePassData')) {
      history.push('/home');
      return;
    }
    const userData = JSON.parse(localStorage.getItem('safePassData'));
    if (userData && (!userData.FirstName || !userData.LastName)) history.push('/home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** callback function for camera.js: When start QRCode Scan and get text from scanning and pass to scent page */
  const onQRCodeScanStart = (scanData) => {
    /** On Page load, set camera false and  camera is on and get scanData redirect to scent page */
    if (camera) {
      if (scanData.text.startsWith('https://')) {
        window.location.href = scanData.text;
      } else {
        history.push({
          pathname: `/scent/${scanData.text}`,
        });
      }
    }
    setCamera(false);
  };

  const onStartClick = () => {
    if (secretCode) {
      history.push({
        pathname: `/scent/${secretCode}`,
      });
    }
  };
  const openModal = (message) => {
    setMessage(message);
    setCamera(false);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <section className="cm-main-content-wrapper">
        <div className="container d-flex flex-wrap align-items-center justify-content-between h-100">
          <div className="cm-cartoon cm-cartoon-pink">
            <img src="resources/images/character2.png" alt="cartoon" />
          </div>
          <Form className="cm-scan-wrapper" form={form} onFinish={onStartClick}>
            <div className="tap_wrapper" onClick={() => setCamera(!camera)}>
              {camera === true ? (
                <div className="scanner img-fluid QRCodeScanner">
                  <Camera onQRCodeScan={onQRCodeScanStart} openModal={openModal} />
                </div>
              ) : (
                <div className="cm-scan-btn">
                  <img className="img-fluid" src="resources/images/scan.png" alt="scan" />
                </div>
              )}
            </div>
            {/* <h2 className="cm-or">OR</h2>
            <div className="cm-scan-input">
              <Input
                type="text"
                name="scanCode"
                id="scanCode"
                placeholder="Enter Game Code"
                autoComplete="off"
                onChange={(e) => setSecretCode(e.target.value)}
              />
              <h2>Enter secret LOL Game Code</h2>
            </div> */}
            <Modal
              title={dataConstant.CameraAccessDenied.Title}
              visible={isModalOpen}
              onCancel={closeModal}
              footer={[
                <Button key="submit" type="primary" onClick={closeModal}>
                  OK
                </Button>,
              ]}
            >
              <p>{message}</p>
            </Modal>
          </Form>
          <div className="cm-cartoon cm-cartoon-startup">
            <img src="resources/images/character-startup.png" alt="cartoon" />
          </div>
        </div>
        <div className="cm-action-arrow">
          <span className="pre-arrow">
            <img src="resources/images/prev-slide.png" alt="prev-slide" />
          </span>
          <span className="next-arrow">
            <img src="resources/images/next-slide.png" alt="next-slide" />
          </span>
        </div>
      </section>
      <section className="cm-bottom-content">
        <img className="img-fluid cm-chain" src="resources/images/chain.png" alt="chain" />
        <div className="container d-flex align-items-center justify-content-between">
          <img className="cm-star left" src="resources/images/stars.png" alt="stars" />
          <div className="cm-play-wrapper">
            <button className="btn btn-primary cm-play-btn" onClick={() => setCamera(!camera)}>
              Start Game
            </button>
          </div>
          <img className="cm-star right" src="resources/images/stars.png" alt="stars" />
        </div>
      </section>
    </React.Fragment>
  );
};
export default ScanQRCode;
