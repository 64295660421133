/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Modal, Input, message, Form, Progress, notification } from 'antd';
import Schema from 'async-validator';
import { Page, Text, View, Document, Image, PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import urlConstant from '../../constants/urlConstant';
import { FileUpload } from '../../services/commonService';
import dataConstant from '../../constants/dataConstant';

const QuestionDetailsPage = (props) => {
  Schema.warning = function () {};
  const [form] = Form.useForm();
  const history = useHistory();
  const { state } = props.location;

  let apiDate = state && state.questionDetails ? moment().format(dataConstant.dateFormat[0]) : '';
  let date = state && state.questionDetails ? moment().format(dataConstant.dateFormat[1]) : '';

  const [visible, setVisible] = useState(false);
  const [questionDetails, setQuestionDetails] = useState();
  const [emailId, setEmailId] = useState('');
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [username, setUserName] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    /** Check localstorage data (Test result and name) on page load  and set state */
    const userData = JSON.parse(localStorage.getItem('safePassData'));
    if (userData && userData.FirstName && userData.LastName) {
      setUserName(`${userData.FirstName} ${userData.LastName}`);
      notification.success({
        message: dataConstant.APIStatus.Success,
        description: 'Questionnaire answers successfully submitted',
        duration: 2,
      });
    } else {
      history.push({
        pathname: '/home',
      });
    }
    if (state === undefined) {
      history.push({
        pathname: '/home',
      });
    } else if (state && state.questionDetails) {
      setQuestionDetails(state.questionDetails);
    }
  }, []);

  /** Send questionnaire Details email  */
  const handleOk = () => {
    const mailFormat = dataConstant.Email.emailFormat;
    if (emailId.match(mailFormat)) {
      setVisible(false);
      setIsSendEmail(true);
      form.resetFields();
      return true;
    }
    message.error(dataConstant.Email.emailValid);
    return false;
  };

  /** Open email model popup  */
  const showModal = () => {
    setVisible(true);
  };

  /** Hide email model popup  */
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  /** Store email id on input change event */
  const getEmail = (e) => {
    setEmailId(e.target.value);
  };

  /** Send PDF data to the api */
  const getBlobData = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.blob);
    const file = new File([e.blob], 'MyResult.pdf', {
      type: 'application/pdf',
    });
    if (isSendEmail === true) {
      const formData = new FormData();
      const model = {
        Name: username,
        Email: emailId,
        Date: apiDate,
      };
      formData.append('file', file);
      formData.append('model', JSON.stringify(model));

      FileUpload(urlConstant.TestResult.SendQuestionDetailsInEmail, formData)
        .then((res) => {
          if (res.status === dataConstant.APIStatus.Success) {
            message.success('Questionnaire details send successfully');
            setIsSendEmail(false);
            setEmailId('');
          }
        })
        .catch((err) => {
          message.error(err);
        });
      setIsSendEmail(false);
    }
  };

  return (
    <div className="additional_result_wrapper">
      <header className="d-flex align-items-center justify-content-between">
        <Link className="back-btn" to="/home">
          <img src="/resources/images/back_arrow_white.png" alt="arrow" />
        </Link>
        <Link className="logo" to="/home">
          <img src="/resources/images/logo-white.png" alt="logo" />
        </Link>
      </header>
      <div className="additional_result_body">
        <div className="progress_wrapper text-center">
          <Progress type="circle" percent={100} />
        </div>
        <div className="action_btns">
          <h1 className="text-center pt-2 pb-2 mb-0">Questionnaire details</h1>
          <a className="next-btn cm-btn-question-email" onClick={showModal}>
            <span>
              Send Questionnaire details as<b> E - mail </b>
            </span>
            {<img className="pdf-download-img" src="/resources/images/next_arrow.png" alt="settings" />}
          </a>
        </div>
        <PDFDownloadLink
          document={
            <Document
              onRender={(e) => {
                getBlobData(e);
              }}
            >
              <Page>
                <View>
                  <View
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#ffffff',
                      padding: 48,
                      width: '90%',
                      margin: 24,
                      position: 'relative',
                    }}
                  >
                    <View
                      style={{
                        alignSelf: 'center',
                        maxWidth: 240,
                        maxHeight: 240,
                      }}
                    >
                      <Image style={{}} src="/resources/images/result_logo.png" alt="logo" />
                    </View>
                    <Text
                      style={{
                        backgroundColor: '#e2e2e2',
                        fontSize: 23,
                        marginBottom: 8,
                        padding: 8,
                      }}
                    >
                      {dataConstant.screenString}
                    </Text>
                    <Text
                      style={{
                        backgroundColor: '#000000',
                        marginBottom: 8,
                        padding: 8,
                        color: '#ffffff',
                        fontSize: 15,
                      }}
                    >
                      {dataConstant.fdaRegisteredString}
                    </Text>
                    <View
                      style={{
                        backgroundColor: '#000000',
                        marginBottom: 8,
                        padding: 8,
                        color: '#ffffff',
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      ></View>
                      <Text
                        style={{
                          color: '#ffffff',
                          fontSize: 14,
                        }}
                      >
                        {username} Completed test on {date}
                      </Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: '#ffffff',
                        marginBottom: 8,
                        padding: 8,
                        color: '#000000',
                        textAlign: 'left',
                      }}
                    >
                      <Text
                        style={{
                          color: '#000000',
                          fontSize: 14,
                        }}
                      >
                        <Text style={{ fontSize: 16, fontWeight: 'black' }}>{`Your Answer Details:\n\n`}</Text>
                        {questionDetails &&
                          questionDetails.QuestionnaireListDetails.map((item, index) => {
                            return (
                              <View key={index}>
                                <View
                                  style={{
                                    color: '#000000',
                                    fontSize: 14,
                                  }}
                                >
                                  <Text>{`${index + 1}.`}</Text>
                                </View>{' '}
                                <View>
                                  <Text>{`${item.Question}\n`}</Text>
                                  <View
                                    style={{
                                      color: item && item.Answer === 'Yes' ? 'green' : '#ff0000',
                                      marginLeft: 16,
                                      textTransform: 'uppercase',
                                    }}
                                  >
                                    <Text>{`${item.Answer}\n\n`}</Text>
                                  </View>
                                </View>
                              </View>
                            );
                          })}
                      </Text>
                    </View>
                  </View>
                </View>
              </Page>
            </Document>
          }
          fileName={`MysafePassQuestionDetails.pdf`}
          className="cm-btn-question-PDF"
        >
          {({ blob, url, loading, error }) => {
            return (
              <>
                <span>
                  {' '}
                  Download Questionnaire details as <b> PDF </b>
                </span>
                <img className="pdf-download-img" src="/resources/images/next_arrow.png" alt="settings" />
              </>
            );
          }}
        </PDFDownloadLink>
      </div>
      <Modal
        title="Get Via Email"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: false,
        }}
        cancelButtonProps={{
          disabled: false,
        }}
      >
        <Form form={form}>
          <Form.Item
            name="email"
            type="email"
            rules={[
              {
                required: true,
                message: dataConstant.Email.emailRequired,
              },
              {
                type: 'email',
                message: dataConstant.Email.emailValid,
              },
            ]}
          >
            <Input placeholder="Enter your E-mail id" type="email" onChange={(e) => getEmail(e)} />
          </Form.Item>
        </Form>
      </Modal>
      <footer>
        <p>
          <b>{dataConstant.footerMessage}</b>
        </p>
      </footer>
    </div>
  );
};

export default QuestionDetailsPage;
