import React from 'react';

const FrontendHeader = () => {
  return (
    <header className="text-center cm-bg-secondary">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="logo" href="/">
            <img src="resources/images/logo.png" alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <a className="logo" href="/">
                <img src="resources/images/logo.png" alt="logo" />
              </a>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav align-items-center me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <button className="btn btn-primary cm-play-btn" type="button">
                    Play Scent Game
                  </button>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="javascript:void(0)">
                    LOL Universe
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)">
                    LOL News
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)">
                    LOL Game Wallet
                  </a>
                </li>
                <li className="cm-stars">
                  <img src="resources/images/stars.png" alt="stars" />
                </li>
              </ul>
              <div className="cm-login">
                <a className="nav-link" href="javascript:void(0)">
                  Log in
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default FrontendHeader;
