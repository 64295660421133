/* eslint-disable no-useless-escape */
const dataConstant = {
  DataSetType: {
    GetAllChartData: 'A',
    GetAllChartDataWithNullId: 'N',
    GetAllChartDataWithOrganizationId: 'O',
  },
  APIStatus: {
    Success: 'Success',
    Failure: 'Failure',
    Warning: 'Warning',
  },
  Role: {
    Admin: 'Admin',
    User: 'User',
    ClientAdmin: 'Client Admin',
  },
  NoImageURL: '/resources/images/NoImage.png',
  resultPageDisclaimer: `Disclaimer: The absence of an abnormal test
  does not rule out diseases or other conditions.
  Consult a healthcare professional, if you have
  symptoms of concern. The results do not
  represent a medical diagnosis and are not in
  place of a medical test of any kind.`,
  // resultPageDisclaimer: `Disclaimer: The absence of an abnormal test does not rule out diseases or other conditions. Consult a healthcare professional, if you have symptoms of concern. The results do not represent a medical diagnosis and are not in place of a medical test of any kind.`,
  footerMessage: 'Powered by MySafePass',
  fdaRegisteredString: 'FDA Registered Class II (510K Exempt) Medical Device',
  screenString: 'OLFACTORY TEST KIT',
  homePageTermsAndConditions: {
    Title: {
      Description: 'DESCRIPTION:',
      Privacy: 'PRIVACY:',
      Caution: 'CAUTION:',
      TermsAndConditions: 'TERMS AND CONDITIONS:',
      HippaAuthTitle: 'HIPAA AUTHORIZATION FOR USE OR DISCLOSURE OF HEALTH INFORMATION',
      ThePatientUser: 'I. THE PATIENT/USER:',
      Authorization: 'II. AUTHORIZATION:',
      Disclosure: 'III. DISCLOSURE:',
      Purpose: 'IV. PURPOSE:',
      Termination: 'V. TERMINATION:',
      Acknowledgment: 'VI. ACKNOWLEDGMENT OF RIGHT:',
    },
    Message: {
      Description:
        "This olfactory device is used to determine if a person has loss-of-smell. The device consists of a 'My Safe Pass™' card which has encapsulated odorants that are scratched and smelled, and an associated App to record and analyze the results. The test devices are only intended to determine whether an olfactory loss (i.e.loss-of-smell) is present. It is not intended to screen or diagnose a disease.",
      Privacy:
        "My Safe Pass™ uses 128-bit encrypted communication and transmits transient 'cookies'. Data is anonymized, we value your privacy. Access to your camera is needed to use the QR reader for spatial mapping.",
      Caution:
        'Do not use if you have allergic reactions to odors or a medical condition in which you have an adverse reaction to smelling odors in succession (e.g.asthma)',
      HippaAuthMessage: 'My Safe Pass™ Olfactory test application information is used for test completion.',
      ThePatientUser:
        'This form is for use when such authorization is required and complies with the Health Insurance Portability and Accountability Act of 1996 (HIPAA) Privacy Standards.',
      Authorization:
        'I authorize My Safe Pass™ and its associated owners, ("Authorized Party") to use or disclose all information gathered, hereinafter known as the "Medical Records."',
      Disclosure:
        'The Authorized Party has my authorization to disclose My Medical Records to the provider I selected in the drop down menu.',
      Purpose: 'The reason for this authorization is to provide gathered data to the Patient/User selected provider.',
      Termination: 'This authorization will terminate upon providers request.',
      Acknowledgment1:
        'I understand that I have the right to revoke this authorization, in writing and at any time, except where uses or disclosures have already been made based upon my original permission. I might not be able to revoke this authorization if its purpose was to obtain insurance.',
      Acknowledgment2:
        'I understand that uses and disclosures already made based upon my original permission cannot be taken back.',
      Acknowledgment3:
        'I understand that it is possible that Medical Records and information used or disclosed with my permission may be re-disclosed by a recipient and no longer protected by the HIPAA Privacy Standards.',
    },
  },
  tableRequest: {
    pageNumber: 1,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage')) ? JSON.parse(localStorage.getItem('RowsPerPage')) : 10,
    SearchText: '',
    filter: {
      Name: '',
    },
    sortColumns: {
      sortColumnName: 'id',
      sortOrder: 'asc',
    },
  },
  resultTableRequest: {
    pageNumber: 1,
    rowsPerPage: JSON.parse(localStorage.getItem('RowsPerPage')) ? JSON.parse(localStorage.getItem('RowsPerPage')) : 10,
    SearchText: '',
    TestKitId: [],
    ResultStatus: '',
    Gender: '',
    LoginOrganizationId: null,
    OrganizationId: [],
    filter: {
      ResultStatus: '',
      Latitude: '',
      Longitude: '',
    },
    sortColumns: {
      sortColumnName: 'id',
      sortOrder: 'desc',
    },
  },
  sortColumnsForCity: {
    sortColumnName: 'city',
    sortOrder: 'asc',
  },
  pagination: {
    pageNumber: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
  },
  TestResultStatus: [
    { label: 'Pass', value: 'Pass' },
    { label: 'Fail', value: 'Fail' },
  ],
  passwordPattern: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,

  manageScentCardData: {
    formId: {
      Title: 'Title',
      StoredPath: 'StoredPath',
    },
  },
  somethingWentWrong: 'Something went wrong',
  manageScentCard: {
    correctAnswerWarning: 'Scent card must contain one correct Option ',
    sortOrderUnique: 'All sort order should be unique',
    scentCardMinimumWarning: 'Scent Card should contain 5 options.',
    scentCardFormName: 'Please enter scent card name',
    scentCardFormSortOrder: 'Please select sort order',
    scentCardFormCorrectAnswer: 'Please enter correct answer',
    scentCardModalOptionName: 'Please select option name',
    scentCardModalSortOrder: 'Please enter sort order',
    scentCardModalSortOrderAlreadyExists: 'This order number is already set',
  },
  manageUser: {
    firstNameRequired: 'Please enter first name!',
    firstNameCharacterLimit: 'First name allowed upto 25 character',
    lastNameRequired: 'Please enter last name!',
    lastNameCharacterLimit: 'Last name allowed upto 25 character',
    passwordRequired: 'Please enter password!',
    confirmPasswordReqired: 'Please enter confirm password!',
    confirmPasswordDoesNotMatch: 'Confirm password doest not match with password!',
    phoneLimit: 'Phone# allowed between 7-15 character',
    roleRequired: 'Role is required',
    invalidNamePattern: 'Special characters are not allowed',
  },
  manageOrganization: {
    organizationNameRequired: 'Please enter organization name!',
    organizationNameCharacterLimit: 'Organization name allowed upto 100 character',
    organizationAddressRequired: 'Please enter organization address!',
    organizationCodeRequired: 'Please enter organization code!',
    organizationCodeCharacterLimit: 'Organization code allowed upto 200 character',
    organizationPhoneRequired: 'Please enter organization phone!',
    organizationEmailRequired: 'Please enter organization email!',
    organizationWebsiteRequired: 'Please enter organization website!',
  },
  resetPassword: {
    newPasswordRequired: 'New Password is required',
    newPasswordMessage: 'Must contain one uppercase, atleast 8 characters, one lowercase, one special character',
  },
  home: {
    firstNameRequired: 'Please enter your First name!',
    lastNameRequired: 'Please enter your Last name!',
    firstNameCharacterLimit: 'First name must be less than 25 characters',
    lastNameCharacterLimit: 'Last name must be less than 25 characters',
    footerHIPPAString:
      'My Safe Pass™ is HIPAA compliant. Your personal information will not be shared with out permission.',
  },
  Email: {
    emailFormat: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    emailRequired: 'Please enter email!',
    emailValid: 'Please enter valid email!',
    emailCharacterLimit: 'Email allowed upto 25 character',
  },
  Website: {
    websiteFormat:
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    websiteRequired: 'Please enter website!',
    websiteValid: 'Please enter valid website!',
  },

  Questions: [
    'Have you failed the olfactory disorder test or noticed changes in taste or smell?',
    'Have you tested positive for COVID-19 in the past 10 days?',
    'Are you currently awaiting results from a COVID-19 test?',
    'Have you been diagnosed with COVID -19 by a licensed doctor in the past 10 days?',
    'Have you been advised of a COVID-19 contact through trace?',
  ],
  UserMedicationQuestions: {
    IsAlzheimerDisease: "Alzheimer's Disease: ",
    IsParkinsonDisease: "Parkinson's Disease: ",
    IsHeadInjuriesConcussion: 'Head Injuries/Concussion: ',
    IsSinoNasalDisease: 'Sino-nasal Disease: ',
    IsOtherNeurologicalDisorder: 'Other Neurological Disorder: ',
  },

  UserFamilyMedicationQuestions: {
    IsAlzheimerDiseaseInFamily: "Alzheimer's Disease: ",
    IsParkinsonDiseaseInFamily: "Parkinson's Disease: ",
    IsOtherNeurologicalDisorderInFamily: 'Other Neurological Disorder: ',
  },
  Race: [
    'White',
    'Black or African American',
    'Hispanic or Latino',
    'Asian',
    'American Indian or Alaska Native',
    'Native Hawaiian or Other Pacific Islander',
    'Some Other Race',
  ],
  NotificationType: {
    Error: 'Error',
  },
  Gender: {
    Male: 'Male',
    Female: 'Female',
  },
  Report: {
    ApiDateFormat: 'YYYY-MM-DD',
    ReportDateFormat: { dateFormat: 'MM/DD/YYYY' },
    SearchByMonth: 'searchByMonth',
    SearchByYear: 'searchByYear',
    YearStartDate: '2022-01-01',
    YearEndDate: '2022-12-31',
  },
  dateFormat: ['YYYYMMDD', 'MMMM DD, YYYY h:mm A'],
  resultStatut: {
    Pass: 'Pass',
    Fail: 'Fail',
    Red: 'red',
    Green: 'green',
    Blue: 'blue',
  },
  AgeValidation: {
    ValidAgeCheck: /^0*(?:[1-9][0-9]?|100)$/g,
  },
  FilterOptionForUserMedication: {
    IsAlzheimerDisease: "Alzheimer's Disease",
    IsParkinsonDisease: "Parkinson's Disease",
    IsHeadInjuriesConcussion: 'Head Injuries/Concussion',
    IsSinoNasalDisease: 'Sino-nasal Disease',
    IsOtherNeurologicalDisorder: 'Other Neurological Disorder',
  },
  FilterOptionForUserFamilyMedication: {
    IsAlzheimerDiseaseInFamily: "Alzheimer's Disease",
    IsParkinsonDiseaseInFamily: "Parkinson's Disease",
    IsOtherNeurologicalDisorderInFamily: 'Other Neurological Disorder',
  },
  UserMedicalFormCheckValues: {
    0: ['Age', 'Gender', 'Race'],
    1: [
      'IsOtherNeurologicalDisorder',
      'IsSinoNasalDisease',
      'IsHeadInjuriesConcussion',
      'IsParkinsonDisease',
      'IsAlzheimerDisease',
    ],
    2: ['IsOtherNeurologicalDisorderInFamily', 'IsParkinsonDiseaseInFamily', 'IsAlzheimerDiseaseInFamily'],
  },
  namePattern: '^[a-zA-Z0-9]*$',
  CameraAccessDenied: {
    Title: 'Camera Access Denied',
    DeviceInUseMsg:
      'Yoyocollectabels.com needs camera access. It seems your camera is being used by other app. Please close all applications and try again.',
    PermissionMessage:
      'Yoyocollectabels.com needs camera access. It seems your browser does not have camera permission. Please give camera permission to the browser, refresh and try again.',
  },
};
export default dataConstant;
