import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

const Pass = () => {
  const history = useHistory();
  const onPlayAgainClick = () => {
    history.push({
      pathname: '/home',
    });
  };
  return (
    <React.Fragment>
      <section className="cm-main-content-wrapper">
        <div className="container d-flex flex-wrap align-items-end justify-content-center justify-content-md-start h-100 position-relative">
          <div className="cm-left-content">
            <div className="cm-cartoon cm-sm-cartoon cm-three-cartoon">
              <img src="resources/images/character-winningwallet.png" alt="cartoon" />
            </div>
            <div className="cm-select-scent cm-three flex-column mb-0">
              <div className="d-flex justify-content-center justify-content-md-start justify-content-xl-center">
                <a href="#">
                  <img src="resources/images/surprise.png" alt="surprise" />
                </a>
                <a href="#">
                  <img src="resources/images/ultra.png" alt="ultra" />
                </a>
                <a href="#">
                  <img src="resources/images/bling-24k.png" alt="bling-24k" />
                </a>
              </div>
              <h2>Collect all cards for more chances to win!</h2>
            </div>
          </div>
          <div className="cm-cartoon cm-ohsoclose-cartoon">
            <img src="resources/images/character-winner.png" alt="cartoon" />
          </div>
        </div>
      </section>
      <section className="cm-bottom-content">
        <img className="img-fluid cm-chain" src="resources/images/chain.png" alt="chain" />
        <div className="container d-flex align-items-center justify-content-between">
          <img className="cm-star left" src="resources/images/stars.png" alt="stars" />
          <div className="cm-play-wrapper d-flex flex-wrap flex-md-nowrap gap-4 justify-content-center">
            <button disabled className="btn btn-primary cm-play-btn">
              Add to Wallet
            </button>
            <button className="btn btn-primary cm-play-btn" onClick={onPlayAgainClick}>
              Play Again
            </button>
          </div>
          <img className="cm-star right" src="resources/images/stars.png" alt="stars" />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Pass;
