import * as React from 'react';
import { Helmet } from 'react-helmet';
import FrontendHeader from './frontendHeader';
import FrontendFooter from './frontendFooter';
export default class FrontendLayout extends React.PureComponent<{}, { children?: React.ReactNode }> {
  public render() {
    return (
      <React.Fragment>
        <main>
          <Helmet>
            <script src="resources/js/custom.js" type="text/javascript" />
            <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js" />
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.min.js" />
          </Helmet>
          <span className="overlay"></span>
          <div className="cm-container">
            <FrontendHeader />
            {this.props.children}
            <FrontendFooter />
          </div>
        </main>
      </React.Fragment>
    );
  }
}
