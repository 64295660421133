import React, { useEffect } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { useHistory } from 'react-router';
import Schema from 'async-validator';
import { Helmet } from 'react-helmet';
import dataConstant from '../../constants/dataConstant';

const { Text } = Typography;

const Home = () => {
  Schema.warning = function () {};
  const [form] = Form.useForm();
  const history = useHistory();

  /** Removed localStorage and open Terms And Conditions popup  on page load */
  useEffect(() => {
    localStorage.removeItem('safePassData');
    localStorage.removeItem('testResultData');
    window.scrollTo(0, 0);
  }, []);

  /** On form submit store name in localStorage and redirect to addOrganization Page */
  // const onFinish = (values) => {
  //   if (values.FirstName && values.LastName) {
  //     const safePassData = localStorage.getItem('safePassData');
  //     let userData = safePassData ? JSON.parse(safePassData) : {};
  //     userData.FirstName = values.FirstName;
  //     userData.LastName = values.LastName;
  //     userData.isAddOrganizationChecked = values.isAddOrganizationChecked == 'true';
  //     localStorage.setItem('safePassData', JSON.stringify(userData));
  //     history.push({
  //       pathname: '/ScanQRCode',
  //     });
  //   }
  // };
  const onStartClick = () => {
    const safePassData = localStorage.getItem('safePassData');
    const userData = safePassData ? JSON.parse(safePassData) : {};
    userData.FirstName = 'Test1';
    userData.LastName = 'Test1';
    userData.isAddOrganizationChecked = false;
    localStorage.setItem('safePassData', JSON.stringify(userData));
    history.push({
      pathname: '/ScanQRCode',
    });
  };
  /** store isAddOrganizationChecked in localStorage On AddOrganization checkbox change event */
  /** const onAddOrganizationChange = (e) => {
    const safePassData = localStorage.getItem('safePassData');
    let userData = safePassData ? JSON.parse(safePassData) : {};
    userData.isAddOrganizationChecked = e.target.checked;
    localStorage.setItem('safePassData', JSON.stringify(userData));
  }; */

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, height=device-height"
        />
      </Helmet>
      <section className="cm-main-content-wrapper">
        <div className="container-fluid d-flex flex-wrap align-items-center justify-content-between h-100 overflow-hidden">
          <div className="cm-steps-wrapper cm-cartoons-steps">
            <img src="resources/images/character-step1.png" alt="step1" />
            <img src="resources/images/character-step2.png" alt="step2" />
            <img src="resources/images/character-step3.png" alt="step3" />
          </div>
        </div>
      </section>
      <section className="cm-bottom-content">
        <img className="img-fluid cm-chain" src="resources/images/chain.png" alt="chain" />
        <div className="container d-flex align-items-center justify-content-between">
          <img className="cm-star left" src="resources/images/stars.png" alt="stars" />
          <div className="cm-play-wrapper">
            <button className="btn btn-primary cm-play-btn" onClick={onStartClick}>
              Start Game
            </button>
          </div>
          <img className="cm-star right" src="resources/images/stars.png" alt="stars" />
        </div>
      </section>
    </React.Fragment>
  );
};
export default Home;
