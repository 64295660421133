/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { Modal, Input, Spin, message, Form, notification } from 'antd';
import { Page, Text, View, Document, Image, PDFDownloadLink, Font, StyleSheet } from '@react-pdf/renderer';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import urlConstant from '../../constants/urlConstant';
import commonService from '../../services/commonService';
import { FileUpload, httpPost } from '../../services/commonService';
import dataConstant from '../../constants/dataConstant';
import messageConstant from '../../constants/messageConstant';

const Result = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [emailModelVisible, setEmailModelVisible] = useState(false);
  const [testResult, setTestResult] = useState();
  const [userName, setUserName] = useState('');
  const [age, setAge] = useState('');
  const [race, setRace] = useState('');
  const [gender, setGender] = useState('');

  const [medicalProvider, setMedicationProvider] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [address, setAddress] = useState('');
  const [IsEmailSend, setIsEmailSend] = useState(false);
  const [checkQuestionnaireStatus, setCheckQuestionnaireStatus] = useState(false);
  const [disorder, setDisorder] = useState();
  const [familyHistory, setFamilyHistory] = useState();
  const [key, setKey] = useState(Math.random());
  const imageRef = useRef(null);
  const date = testResult ? moment().format(dataConstant.dateFormat[1]) : '';

  useEffect(() => {
    if (organizationId) {
      getOrganizationById();
    }
    if (testResult) {
      /** Check Status of Questionnaire while change in testResult */
      checkStatus(testResult.testResultId);
    }
  }, [testResult]);

  useEffect(() => {
    window.scrollTo(0, 0);
    /** Check localstorage data (Test result and name) on page load  and set state */
    if (localStorage.getItem('testResultData') && localStorage.getItem('safePassData')) {
      const objTestResult = JSON.parse(localStorage.getItem('testResultData'));
      const objSafePassData = JSON.parse(localStorage.getItem('safePassData'));
      if (!objSafePassData.FirstName || !objSafePassData.LastName) {
        history.push('/home');
      }
      setUserName(`${objSafePassData.FirstName} ${objSafePassData.LastName}`);
      setAge(objSafePassData.Age);
      setGender(objSafePassData.Gender);
      setRace(objSafePassData.Race);
      setOrganizationId(objSafePassData.organizationId);
      setTestResult(objTestResult);
      setKey(Math.random());
      const objDisorders = [];
      if (objSafePassData.IsAlzheimerDisease) {
        objDisorders.push("Alzheimer's Disease");
      }
      if (objSafePassData.IsHeadInjuriesConcussion) {
        objDisorders.push('Head Injuries/Concussion');
      }
      if (objSafePassData.IsOtherNeurologicalDisorder) {
        objDisorders.push('Other Neurological Disorder');
      }
      if (objSafePassData.IsParkinsonDisease) {
        objDisorders.push("Parkinson's Disease");
      }
      if (objSafePassData.IsSinoNasalDisease) {
        objDisorders.push('Sino-nasal Disease');
      }
      setDisorder(objDisorders);
      const objFamilyHistory = [];
      if (objSafePassData.IsAlzheimerDiseaseInFamily) {
        objFamilyHistory.push("Alzheimer's Disease");
      }
      if (objSafePassData.IsParkinsonDiseaseInFamily) {
        objFamilyHistory.push("Parkinson's Disease");
      }
      if (objSafePassData.IsOtherNeurologicalDisorderInFamily) {
        objFamilyHistory.push('Other Neurological Disorder');
      }
      setFamilyHistory(objFamilyHistory);
    } else {
      /** if localstorage data (Test result and name) on page load  not found , redirect to home page */
      history.push('/home');
    }
  }, []);

  /** Check Status of Questionnaire test is completed or not */
  const checkStatus = (testResultID) => {
    httpPost(urlConstant.TestResult.GetTestStatus + testResultID)
      .then((response) => {
        setCheckQuestionnaireStatus(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /** Show email popup */
  const showModal = () => {
    setEmailModelVisible(true);
  };

  /** On click of Ok button of email popup */
  const handleEmailPopupOk = (e) => {
    const mailFormat = dataConstant.Email.emailFormat;
    if (emailId.match(mailFormat)) {
      setEmailModelVisible(false);
      setIsEmailSend(true);
      form.resetFields();
      return true;
    }
    message.error(dataConstant.Email.emailValid);
    return false;
  };

  /** On click of Cancel button of email popup */
  const handleEmailPopupCancel = (e) => {
    setEmailModelVisible(false);
    form.resetFields();
  };

  const styles = StyleSheet.create({
    pdfDesign: {
      fontSize: 12,
      fontFamily: 'Lato Bold',
      lineHeight: 0.9,
    },
  });

  Font.register({
    family: 'Lato Bold',
    src: 'https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf',
  });

  Font.register({
    family: 'Lato',
    src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
  });
  /** downloadImage function called from downlod image button */
  const downloadImage = async () => {
    document.body.style.zoom = 1;
    const dataUrl = await htmlToImage.toJpeg(imageRef.current, {
      quality: 0.95,
      backgroundColor: '#ffffff',
    });
    // to download direct in browser image
    const link = document.createElement('a');
    link.download = `MysafePassResult_${date}.jpeg`;
    link.href = dataUrl;
    link.click();
  };

  /** download pkpass function called from add to wallet */
  const pkPassDownload = () => {
    const pkPassDownloadHeaders = new Headers();
    pkPassDownloadHeaders.append('Content-Type', 'application/json');
    // eslint-disable-next-line no-redeclare
    const requestOptions = {
      method: 'POST',
      headers: pkPassDownloadHeaders,
      body: JSON.stringify({
        Name: userName,
        Date: date,
        Result: testResult.resultStatus,
        Score: testResult.rightAnswerCount,
      }),
      redirect: 'follow',
    };
    fetch(urlConstant.PkPass.generatePkPass, requestOptions)
      .then((response) => response.blob())
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: 'application/vnd.apple.pkpass',
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `MysafePassResult_${date}.pkpass`;
          link.click();
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  /** Set email id on change of email text box */
  const getEmail = (e) => {
    setEmailId(e.target.value);
  };

  /** Get/Set file and doenload as PDF and send file in to email attachment in SendResultInEmail API */
  const getBlobData = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.blob);
    const file = new File([e.blob], `MysafePassResult_${date}.pdf`, {
      type: 'application/pdf',
    });
    if (IsEmailSend === true) {
      const formData = new FormData();
      const model = {
        Name: userName,
        Email: emailId,
        Date: testResult ? moment().format(dataConstant.dateFormat[0]) : '',
      };
      formData.append('file', file);
      formData.append('model', JSON.stringify(model));
      FileUpload(urlConstant.TestResult.SendResultInEmail, formData)
        .then((res) => {
          if (res.status === dataConstant.APIStatus.Success) {
            message.success(messageConstant.Result.SendResultInEmailSucess);
            setIsEmailSend(false);
            setEmailId('');
          }
        })
        .catch((err) => {
          message.error(err);
        });
      setIsEmailSend(false);
    }
  };

  const getOrganizationById = () => {
    commonService
      .httpGet(`${urlConstant.Organization.GetOrganizationById}?Id=${organizationId}`)
      .then((response) => {
        if (response && response.status === dataConstant.APIStatus.Success) {
          setAddress(response.data.organizationAddress);
          setMedicationProvider(response.data.organizationName);
          setPhoneNumber(response.data.organizationPhone);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Spin key={key} tip="Loading..." size="large" spinning={!testResult}>
      <div className="result_page">
        <header className="d-flex align-items-center justify-content-between">
          <a className="back-btn">
            <img
              onClick={() => {
                history.replace({
                  pathname: '/home',
                });
              }}
              src="/resources/images/back_arrow_white.png"
              alt="arrow"
            />
          </a>
          <a className="logo" href="/home">
            <img src="/resources/images/logo-white.png" alt="logo" />
          </a>
        </header>
        <div className="result_body">
          <div ref={imageRef}>
            <section className="result_wrapper">
              <img className="result_logo" src="/resources/images/splash_logo.png" alt="logo" />
              <p className="bg_light">
                <b>{dataConstant.screenString}</b>
              </p>
              <p className="bg_dark">{dataConstant.fdaRegisteredString}</p>
              <div
                className={
                  testResult && testResult.resultStatus === 'Pass'
                    ? 'd-flex align-items-center justify-content-center mb-3 result_pass bg_dark'
                    : 'd-flex align-items-center justify-content-center mb-3 result_fail bg_dark'
                }
              >
                <span className={testResult && testResult.resultStatus === 'Pass' ? 'pass' : 'fail'}></span>
                <p>{testResult && testResult.resultStatus === 'Pass' ? 'Passed' : 'Failed'}</p>
              </div>
              <div className="bg_light py-3">
                <ul className="result-content">
                  <li>
                    <label>Patients Name:</label>
                    {userName}
                  </li>
                  <li>
                    <label>Test completed on:</label>
                    {date}
                  </li>
                  {organizationId && (
                    <>
                      <li>
                        <label>Medical Provider:</label>
                        {medicalProvider}
                      </li>
                      <li>
                        <label>Address:</label>
                        {address ? <>{address}</> : <span>-</span>}
                      </li>
                      <li>
                        <label>Office Phone:</label>
                        {phoneNumber ? <>{phoneNumber} </> : <span>-</span>}
                      </li>
                    </>
                  )}
                </ul>
                <h3 className="result-ttl">Medical Assessment Test Questions</h3>
                <ul className="d-flex align-items-center flex-wrap result-content">
                  <li className="mr-2">
                    <label className="w-auto">Age:</label>
                    {age}
                  </li>
                  <li>
                    <label className="w-auto">Gender:</label>
                    {gender}
                  </li>
                </ul>
                <ul className="result-content">
                  <li>
                    <label>Race:</label>
                    {race}
                  </li>
                  <li>
                    <label>Reported Disorders:</label>
                    {disorder && disorder.length > 0 ? (
                      <span>
                        {disorder.map((item, index) => (
                          <span key={index}>
                            {item}
                            {index < disorder.length - 1 ? ', ' : ''}
                          </span>
                        ))}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </li>
                  <li className={disorder && disorder.length > 0 ? 'mt-4' : ''}>
                    <label>Reported Family History:</label>
                    {familyHistory && familyHistory.length > 0 ? (
                      <span>
                        {familyHistory.map((item, index) => (
                          <React.Fragment key={index}>
                            {item}
                            {index < familyHistory.length - 1 ? ', ' : ''}
                          </React.Fragment>
                        ))}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </li>
                </ul>
              </div>
              {testResult && testResult.resultStatus === 'Pass' ? (
                <div className="result_desc">
                  <p>
                    Your result indicates normal olfactory function. Use this pass to verify that you have been Screened
                    for olfactory disorder, a strong indicator symptom of Alzheimer's, Parkinson's, Covid-19,
                    Concussion, Head Trauma (TBI), and other conditions (e.g. neurological disorders).
                  </p>
                  <p>
                    It is advised to seek medical attention from a healthcare professional. A medical diagnosis can only
                    be provided by a licensed professional.
                  </p>
                  <p className="disclaimer">{dataConstant.resultPageDisclaimer}</p>
                </div>
              ) : (
                <div className="result_desc">
                  <p>
                    You have failed your test. Your result indicates abnormal olfactory function which is a strong
                    indicator symptom of Alzheimer's, Parkinson's, Covid-19, Concussion, Head Trauma (TBI), and other
                    conditions e.g. neurological disorders.
                  </p>
                  <p>
                    It is advised to seek medical attention from a healthcare professional. A medical diagnosis can only
                    be provided by a licensed professional.
                  </p>
                  <p className="disclaimer">{dataConstant.resultPageDisclaimer}</p>
                </div>
              )}
            </section>
          </div>
          <section className="actions_wrapper">
            <div className="row mx-0">
              <div className="action_btns left col-6">
                {/* <a className="settings-btn" href={urlConstant.homePCROption.targetURL}>
                  <span>
                    Click here for Home <b> PCR Test Option </b>
                  </span>
                  <img src="/resources/images/settings.png" alt="settings" />
                </a> */}
                <a
                  className="next-btn"
                  onClick={() => {
                    checkQuestionnaireStatus === false
                      ? history.push({
                          pathname: '/additionalQuestion',
                        })
                      : notification.warning({
                          message: messageConstant.Result.TestComplated,
                          description: messageConstant.Result.TestComplatedWarning,
                          placement: 'topRight',
                        });
                  }}
                >
                  <span>
                    <b> COVID - 19 Health </b>Screening Questionnaire Optional
                  </span>
                  <img src="/resources/images/next_arrow.png" alt="settings" />
                </a>
                <PDFDownloadLink
                  document={
                    <Document
                      onRender={(e) => {
                        getBlobData(e);
                      }}
                    >
                      <Page size="A4" style={styles.pdfDesign}>
                        <View>
                          <View
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#ffffff',
                              padding: 10,
                              width: '90%',
                              marginLeft: 24,
                              marginRight: 24,
                              position: 'relative',
                            }}
                          >
                            <View
                              style={{
                                alignSelf: 'center',
                                maxWidth: 120,
                                maxHeight: 110,
                                marginBottom: 10,
                              }}
                            >
                              <Image style={{}} src="/resources/images/splash_logo.png" alt="logo" />
                            </View>
                            <Text
                              style={{
                                backgroundColor: '#e2e2e2',
                                fontSize: 18,
                                marginBottom: 8,
                                paddingTop: 5,
                                paddingBottom: 10,
                                color: '#333333',
                                fontWidth: 900,
                              }}
                            >
                              {dataConstant.screenString}
                            </Text>
                            <Text
                              style={{
                                marginBottom: 8,
                                backgroundColor: '#000000',
                                paddingTop: 5,
                                paddingBottom: 10,
                                color: '#ffffff',
                                fontSize: 15,
                              }}
                            >
                              {dataConstant.fdaRegisteredString}
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 10,
                                backgroundColor: '#000000',
                              }}
                            >
                              <View style={{ maxHeight: 60, maxWidth: 60 }}>
                                <Image
                                  src={
                                    testResult && testResult.resultStatus === 'Pass'
                                      ? '/resources/images/check.png'
                                      : '/resources/images/cancel.png'
                                  }
                                  alt=""
                                  height="42"
                                />
                              </View>
                              <View
                                style={{
                                  marginBottom: 20,
                                  color: testResult && testResult.resultStatus === 'Pass' ? 'green' : '#ff0000',
                                  fontSize: 56,
                                  textTransform: 'uppercase',
                                  fontWidth: 600,
                                  marginLeft: 16,
                                }}
                              >
                                <Text>{testResult && testResult.resultStatus === 'Pass' ? 'Passed' : 'Failed'}</Text>
                              </View>
                            </View>
                            <View
                              style={{
                                backgroundColor: '#e2e2e2',
                                marginBottom: 8,
                                padding: 15,
                                paddingLeft: 25,
                                paddingRight: 25,
                                color: '#333333',
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginBottom: '5px',
                                }}
                              >
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'left',
                                    paddingRight: '10px',
                                    fontWeight: 'bold',
                                    wordBreak: 'word-break',
                                    width: '100%',
                                  }}
                                >
                                  <Text
                                    style={{
                                      textDecoration: 'underline',
                                      marginRight: '10px',
                                    }}
                                  >
                                    Patients Name:
                                  </Text>
                                  <Text> {userName}</Text>
                                </Text>
                              </View>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginBottom: '5px',
                                }}
                              >
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'right',
                                    paddingRight: '2px',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  Test completed on:
                                </Text>
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    width: '60%',
                                  }}
                                >
                                  {date}
                                </Text>
                              </View>
                              {organizationId && (
                                <>
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      marginBottom: '5px',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: '#333333',
                                        textAlign: 'left',
                                        paddingRight: '10px',
                                        fontWeight: 'bold',
                                        wordBreak: 'word-break',
                                        width: '100%',
                                      }}
                                    >
                                      <Text
                                        style={{
                                          textDecoration: 'underline',
                                          marginRight: '10px',
                                        }}
                                      >
                                        Medical Provider:
                                      </Text>
                                      <Text> {medicalProvider}</Text>
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      marginBottom: '5px',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: '#333333',
                                        textAlign: 'left',
                                        paddingRight: '10px',
                                        fontWeight: 'bold',
                                        wordBreak: 'word-break',
                                        width: '100%',
                                      }}
                                    >
                                      <Text
                                        style={{
                                          textDecoration: 'underline',
                                          marginRight: '10px',
                                        }}
                                      >
                                        Address:
                                      </Text>
                                      <Text> {address ? <u>{address}</u> : <span>-</span>}</Text>
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      marginBottom: '5px',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: '#333333',
                                        textAlign: 'right',
                                        paddingRight: '2px',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                      }}
                                    >
                                      Office Phone:
                                    </Text>
                                    <Text
                                      style={{
                                        color: '#333333',
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        width: '60%',
                                      }}
                                    >
                                      {phoneNumber ? <>{phoneNumber}</> : <span>-</span>}
                                    </Text>
                                  </View>
                                </>
                              )}

                              <View style={{ marginBottom: '5px' }}>
                                <Text
                                  style={{
                                    color: '#333333',
                                    fontSize: 16,
                                    textAlign: 'center',
                                    fontWeight: 'black',
                                    width: '100%',
                                    marginVertical: '15',
                                  }}
                                >
                                  Medical Assessment Test Questions
                                </Text>
                              </View>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginBottom: '5px',
                                }}
                              >
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'right',
                                    paddingRight: '2px',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  Age:
                                </Text>
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    paddingRight: '10px',
                                  }}
                                >
                                  {age}
                                </Text>
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'right',
                                    paddingRight: '2px',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  Gender:
                                </Text>
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    paddingRight: '10px',
                                  }}
                                >
                                  {gender}
                                </Text>
                              </View>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  marginBottom: '5px',
                                }}
                              >
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'right',
                                    paddingRight: '2px',
                                    fontWeight: 700,
                                    textDecoration: 'underline',
                                  }}
                                >
                                  <h1>Race:</h1>
                                </Text>
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    paddingRight: '10px',
                                    width: '60%',
                                  }}
                                >
                                  {race}
                                </Text>
                              </View>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start',
                                  marginBottom: disorder && disorder.length > 0 ? '10px' : '5px',
                                }}
                              >
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'left',
                                    paddingRight: '10px',
                                    fontWeight: 'bold',
                                    wordBreak: 'word-break',
                                    width: '100%',
                                    lineHeight: '1.2',
                                  }}
                                >
                                  <Text
                                    style={{
                                      textDecoration: 'underline',
                                      marginRight: '10px',
                                    }}
                                  >
                                    Reported Disorders:
                                  </Text>
                                  <Text>
                                    {' '}
                                    {disorder && disorder.length > 0 ? (
                                      <span>
                                        {disorder.map((item, index) => (
                                          <span key={index}>
                                            {item}
                                            {index < disorder.length - 1 ? ', ' : ''}
                                          </span>
                                        ))}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </Text>
                                </Text>
                              </View>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <Text
                                  style={{
                                    color: '#333333',
                                    textAlign: 'left',
                                    paddingRight: '10px',
                                    fontWeight: 'bold',
                                    wordBreak: 'word-break',
                                    width: '100%',
                                    lineHeight: '1.2',
                                  }}
                                >
                                  <Text
                                    style={{
                                      textDecoration: 'underline',
                                      marginRight: '10px',
                                    }}
                                  >
                                    Reported Family History:
                                  </Text>
                                  <Text>
                                    {' '}
                                    {familyHistory && familyHistory.length > 0 ? (
                                      <span>
                                        {familyHistory.map((item, index) => (
                                          <React.Fragment key={index}>
                                            {item}
                                            {index < familyHistory.length - 1 ? ', ' : ''}
                                          </React.Fragment>
                                        ))}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </Text>
                                </Text>
                              </View>
                            </View>
                            {testResult && testResult.resultStatus === 'Pass' ? (
                              <>
                                <Text
                                  style={{
                                    paddingRight: 24,
                                    paddingLeft: 16,
                                    lineHeight: 1.25,
                                    marginBottom: 5,
                                    flexGrow: 1,
                                    textAlign: 'left',
                                    fontFamily: 'Lato',
                                  }}
                                >
                                  Your result indicates normal olfactory function. Use this pass to verify that you have
                                  been Screened for olfactory disorder, a strong indicator symptom of Alzheimer's,
                                  Parkinson's, Covid-19, Concussion, Head Trauma (TBI), and other conditions (e.g.
                                  neurological disorders).
                                  <br />
                                </Text>
                                <Text
                                  style={{
                                    paddingRight: 24,
                                    paddingLeft: 16,
                                    lineHeight: 1.25,
                                    marginTop: 5,
                                    textAlign: 'left',
                                    fontFamily: 'Lato',
                                  }}
                                >
                                  It is advised to seek medical attention from a healthcare professional. A medical
                                  diagnosis can only be provided by a licensed professional.
                                </Text>
                              </>
                            ) : (
                              <>
                                <Text
                                  style={{
                                    paddingRight: 24,
                                    paddingLeft: 16,
                                    lineHeight: 1.25,
                                    marginBottom: 5,
                                    flexGrow: 1,
                                    textAlign: 'left',
                                    fontFamily: 'Lato',
                                  }}
                                >
                                  You have failed your test. Your result indicates abnormal olfactory function which is
                                  a strong indicator symptom of Alzheimer's, Parkinson's, Covid-19, Concussion, Head
                                  Trauma (TBI), and other conditions e.g. neurological disorders.
                                </Text>
                                <Text
                                  style={{
                                    paddingRight: 24,
                                    paddingLeft: 16,
                                    lineHeight: 1.25,
                                    marginTop: 5,
                                    textAlign: 'left',
                                    fontFamily: 'Lato',
                                  }}
                                >
                                  It is advised to seek medical attention from a healthcare professional. A medical
                                  diagnosis can only be provided by a licensed professional.
                                </Text>
                              </>
                            )}
                          </View>
                        </View>
                      </Page>
                    </Document>
                  }
                  fileName={`MysafePassResult${date}.pdf`}
                  className={'next-btn'}
                >
                  {() => {
                    return (
                      <>
                        <span>
                          {' '}
                          Download result as <b> PDF </b>
                        </span>
                        <img className="pdf-download-img" src="/resources/images/next_arrow.png" alt="settings" />
                      </>
                    );
                  }}
                </PDFDownloadLink>
                <a className="next-btn" onClick={downloadImage}>
                  <span>
                    Download results as <b> Image </b>
                  </span>
                  <img src="/resources/images/next_arrow.png" alt="settings" />
                </a>
              </div>
              <div className="action_btns col-6">
                <a className="settings-btn" onClick={pkPassDownload}>
                  <span>
                    Add to <b> Wallet </b>
                  </span>
                  <img src="/resources/images/settings.png" alt="settings" />
                </a>
                <a className="next-btn" onClick={showModal}>
                  <span>
                    Send Results as <b> E - mail </b>
                  </span>
                  <img src="/resources/images/next_arrow.png" alt="settings" />
                </a>
              </div>
            </div>
            <Modal
              title="Get Via Email"
              visible={emailModelVisible}
              onOk={handleEmailPopupOk}
              onCancel={handleEmailPopupCancel}
              okButtonProps={{
                disabled: false,
              }}
              cancelButtonProps={{
                disabled: false,
              }}
            >
              <Form form={form}>
                <Form.Item
                  name="email"
                  type="email"
                  rules={[
                    {
                      required: true,
                      message: dataConstant.Email.emailRequired,
                    },
                    {
                      type: 'email',
                      message: dataConstant.Email.emailValid,
                    },
                  ]}
                >
                  <Input placeholder="Enter your E-mail id" type="email" onChange={(e) => getEmail(e)} />
                </Form.Item>
              </Form>
            </Modal>
            {/* <p className="result_desc">{dataConstant.resultPageDisclaimer}</p> */}
          </section>
        </div>
        <footer>
          <p>
            <b>{dataConstant.footerMessage}</b>
          </p>
        </footer>
      </div>
    </Spin>
  );
};
export default Result;
