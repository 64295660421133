import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Switch, notification, Divider, Select, Space, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import urlConstant from '../../../constants/urlConstant';
import commonService from '../../../services/commonService';
import dataConstant from '../../../constants/dataConstant';
import messageConstant from '../../../constants/messageConstant';

const ManageUser = (props) => {
  const [userId, setUserId] = useState(props.match.params.Id);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);
  const history = useHistory();
  const messageTitle = 'User';

  useEffect(() => {
    getRoleList();
    if (userId) {
      getUserById();
    } else {
      form.setFieldsValue({
        isActive: true,
      });
    }
  }, [userId]);

  const getRoleList = () => {
    commonService.httpGet(urlConstant.Role.getRoles, true).then((response) => {
      if (response.data) {
        setRoles(response.data);
      }
    });
  };

  const getUserById = () => {
    commonService.httpGet(`${urlConstant.User.GetUserById}?Id=${userId}`, true).then((response) => {
      if (response.data) {
        form.setFieldsValue({
          Id: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phone: response.data.phone,
          isActive: response.data.isActive,
          roleId: response.data.roleID,
        });
      }
    });
  };

  const onFinish = (userDetail) => {
    setLoader(true);
    const requestURL = userDetail.Id > 0 ? urlConstant.User.UpdateUser : urlConstant.User.CreateUser;

    commonService.httpPost(requestURL, userDetail, true).then((response) => {
      if (response && response.status === dataConstant.APIStatus.Success) {
        setLoader(false);
        notification.success({
          message: messageTitle,
          description: response.message,
        });
        redirectToUser();
      } else {
        setLoader(false);
        notification.warning({
          message: messageTitle,
          description: response.message,
        });
      }
    });
  };

  const redirectToUser = () => {
    history.push({
      pathname: `/admin/user`,
    });
  };

  if (loader) {
    return (
      <Space size="middle" className="cmLoader">
        <Spin size="small"></Spin>
      </Space>
    );
  }

  return (
    <div className="manage-test-kit">
      <div className="page-header">
        <strong>{userId ? 'Edit User' : 'Create User'}</strong>
      </div>
      <div className="row cm-add-scent-wrapper">
        <div className="col-sm-12">
          <div className="card pt-5">
            <Form
              form={form}
              name="userForm"
              autoComplete="off"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
            >
              <Form.Item name="Id" style={{ display: 'none' }}>
                <Input size="default" />
              </Form.Item>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: dataConstant.manageUser.firstNameRequired },
                  { max: 25, message: dataConstant.manageUser.firstNameCharacterLimit },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: dataConstant.manageUser.lastNameRequired },
                  { max: 25, message: dataConstant.manageUser.lastNameCharacterLimit },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: dataConstant.Email.emailRequired },
                  { type: 'email', message: dataConstant.Email.emailValid },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              {!userId && (
                <>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: dataConstant.manageUser.passwordRequired,
                      },
                      {
                        pattern: dataConstant.passwordPattern,
                        message: messageConstant.InvalidPasswordPattern,
                      },
                    ]}
                  >
                    <Input.Password autoComplete="off" />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: dataConstant.manageUser.confirmPasswordReqired,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(dataConstant.manageUser.confirmPasswordDoesNotMatch));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="phone"
                label="Phone#"
                rules={[{ min: 7, max: 17, message: dataConstant.manageUser.phoneLimit }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                id="roleId"
                name="roleId"
                label="Select Role"
                rules={[{ required: true, message: dataConstant.manageUser.roleRequired }]}
              >
                <Select placeholder="Select Role" allowClear>
                  {roles.length > 0 &&
                    roles.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="isActive" label="Active" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Divider className="m-0 mb-4" />
              <Form.Item className="text-right end mb-4" justify="end">
                <Button type="default" htmlType="button" className="mr-3" onClick={redirectToUser}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" className="mr-3">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
