import React from 'react';
import { useHistory } from 'react-router';

const Fail = () => {
  const history = useHistory();
  const onPlayAgainClick = () => {
    history.push({
      pathname: '/home',
    });
  };

  return (
    <React.Fragment>
      <section className="cm-main-content-wrapper">
        <div className="container d-flex flex-wrap align-items-end h-100 position-relative">
          <div className="cm-left-content">
            <div className="cm-cartoon cm-sm-cartoon">
              <img src="resources/images/character.png" alt="cartoon" />
            </div>
            <div className="cm-select-scent">
              <a href="#">
                <img src="resources/images/surprise.png" alt="surprise" />
              </a>
              <a href="#">
                <img src="resources/images/ultra.png" alt="ultra" />
              </a>
              <a href="#">
                <img src="resources/images/bling-24k.png" alt="bling-24k" />
              </a>
            </div>
          </div>
          <div className="cm-cartoon cm-ohsoclose-cartoon">
            <img src="resources/images/character-ohsoclose.png" alt="cartoon" />
          </div>
        </div>
      </section>
      <section className="cm-bottom-content">
        <img className="img-fluid cm-chain" src="resources/images/chain.png" alt="chain" />
        <div className="container d-flex align-items-center justify-content-between">
          <img className="cm-star left" src="resources/images/stars.png" alt="stars" />
          <div className="cm-play-wrapper">
            <button className="btn btn-secondary cm-play-btn" onClick={onPlayAgainClick}>
              Play Again
            </button>
          </div>
          <img className="cm-star right" src="resources/images/stars.png" alt="stars" />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Fail;
