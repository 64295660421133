/* eslint-disable */
import * as React from "react";
import { Route } from "react-router";
import FrontendLayout from "./components/layouts/frontendLayout";
import AdminLayout from "./components/layouts/adminLayout";
import FullLayout from "./components/layouts/fullLayout";
import Scent from "./components/scent/scent";
import Chart from "./components/chart/chart";
import Home from "./components/home/home";
import Result from "./components/result/result";
import ScanQRCode from "./components/scanQRCode/scanQRCode";
import Camera from "./Test/Camera";
import Dashboard from "./components/admin/dashBoard/dashboard";
import User from "./components/admin/user/user";
import Login from "./components/login/login";
import TestKit from "./components/admin/testKit/testKit";
import TestResult from "./components/admin/playResults/testResult";
import ManageTestKit from "./components/admin/testKit/manageTestkit";
import EditOptionDetails from "./components/admin/testKit/editOptionDetails";
import ManageScentCardData from "./components/admin/scentCardData/manageScentCardData";
import ScentCardData from "./components/admin/scentCardData/scentCardData";
import ManageUser from "./components/admin/user/manageUser";
import QuestionPage from "./components/additionalQuestion/questionPage";
import QuestionDetailsPage from "./components/additionalQuestion/questionDetailsPage";
import AddOrganizationPage from "./components/organization/addOrganizationPage";
import Client from "./components/admin/client/client";
import ManageOrganization from "./components/admin/client/manageOrganization";
import DataLocation from "./components/admin/reports/dataLocation";
import MapData from "./components/admin/reports/mapData";
import PassFailData from "./components/admin/reports/passFailData";
import UserMedicationQuestion from "./components/userMedicalQuestion/userMedicationQuestion";
import "antd/dist/antd.css";
import "./custom.css";
import Pass from "./components/result/pass";
import Fail from "./components/result/fail";

const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

export default () => (
  <>
    <RouteWithLayout exact path="/" component={Home} layout={FrontendLayout} />
    <RouteWithLayout path="/scent/:id/" component={Scent} layout={FrontendLayout} />
    <RouteWithLayout path="/chart" component={Chart} layout={FrontendLayout} />
    <RouteWithLayout path="/home" component={Home} layout={FrontendLayout} />
    <RouteWithLayout path="/result" component={Result} layout={FrontendLayout} />
    <RouteWithLayout path="/scanQRCode" component={ScanQRCode} layout={FrontendLayout} />
    <RouteWithLayout path="/Camera" component={Camera} layout={FrontendLayout} />
    <RouteWithLayout path="/addOrganizationPage" component={AddOrganizationPage} layout={FrontendLayout} />
    <RouteWithLayout path="/additionalQuestion" component={QuestionPage} layout={FrontendLayout} />
    <RouteWithLayout path="/userMedicationQuestion" component={UserMedicationQuestion} layout={FrontendLayout} />
    <RouteWithLayout path="/additionalQuestionDetails" component={QuestionDetailsPage} layout={FrontendLayout} />
    <RouteWithLayout path="/pass" component={Pass} layout={FrontendLayout} />
    <RouteWithLayout path="/fail" component={Fail} layout={FrontendLayout} />

    <RouteWithLayout exact path='/admin' component={Dashboard} layout={AdminLayout} />
    <RouteWithLayout path='/admin/dashboard' component={Dashboard} layout={AdminLayout} />
    <RouteWithLayout path='/admin/testresult' component={TestResult} layout={AdminLayout} />
    <RouteWithLayout path='/admin/user' component={User} layout={AdminLayout} />
    <RouteWithLayout path='/admin/manageuser/:Id?' component={ManageUser} layout={AdminLayout} />
    <RouteWithLayout path='/admin/managetestkit/:Id?/:ScentCardId?' component={ManageTestKit} layout={AdminLayout} />
    <RouteWithLayout path='/admin/manageScentCard/:Id/:ScentCardId' component={EditOptionDetails} layout={AdminLayout} />
    <RouteWithLayout path='/admin/reports/dataLocation' component={DataLocation} layout={AdminLayout} />
    <RouteWithLayout path='/admin/Reports/locationData' component={MapData} layout={AdminLayout} />
    <RouteWithLayout path='/admin/Reports/passFailData' component={PassFailData} layout={AdminLayout} />



    <RouteWithLayout path="/admin/login" component={Login} layout={FullLayout} />
    {/* <RouteWithLayout path='/admin/manageScentCard/:Id?' component={EditOptionDetails} layout={AdminLayout} /> */}
    <RouteWithLayout path="/admin/testKit" component={TestKit} layout={AdminLayout} />
    <RouteWithLayout path="/admin/client" component={Client} layout={AdminLayout} />
    <RouteWithLayout path="/admin/manageOrganization/:Id?" component={ManageOrganization} layout={AdminLayout} />
    <RouteWithLayout path="/admin/createScentCardData" component={ManageScentCardData} layout={AdminLayout} />
    <RouteWithLayout path="/admin/editScentCardData/:Id" component={ManageScentCardData} layout={AdminLayout} />
    <RouteWithLayout path="/admin/scentCardData" component={ScentCardData} layout={AdminLayout} />
  </>
);
