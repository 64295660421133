import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Select, Spin, Radio, Form, Button, notification, Col, Row, Slider, Input, Carousel, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { httpPost } from '../../services/commonService';
import urlConstant from '../../constants/urlConstant';
import dataConstant from '../../constants/dataConstant';
import _ from 'lodash';
import Schema from 'async-validator';
import messageConstant from '../../constants/messageConstant';
const { Option } = Select;

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        fontSize: '18px',
        lineHeight: '1.5715',
      }}
      onClick={onClick}
    >
      <RightOutlined />
    </div>
  );
};
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        fontSize: '18px',
        lineHeight: '1.5715',
      }}
      onClick={onClick}
    >
      <LeftOutlined />
    </div>
  );
};

const UserMedicationQuestion = () => {
  const carouselRef = useRef();
  Schema.warning = function () {};
  const history = useHistory();
  const [form] = Form.useForm();
  const [testResultId, setTestResultId] = useState();
  const [inputValue, setInputValue] = useState();
  const [isLast, setIsLast] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const layout = {
    labelCol: { xs: { span: 10 }, sm: { span: 8 }, md: { span: 8 }, lg: { span: 8 } },
    wrapperCol: { xs: { span: 14 }, sm: { span: 12 }, md: { span: 12 }, lg: { span: 12 } },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    /** Check localstorage data (name) on page load */
    const userTestData = localStorage.getItem('testResultData');
    let testData = userTestData ? JSON.parse(userTestData) : null;
    if (testData && testData.testResultId) {
      setTestResultId(testData.testResultId);
    } else {
      history.push('/home');
    }
  }, []);

  const onChange = (newValue) => {
    setInputValue(typeof newValue == 'number' ? newValue : parseInt(newValue.target.value));
    form.setFieldsValue({
      Age: typeof newValue == 'number' ? String(newValue) : String(newValue.target.value),
    });
  };
  const onPageChange = (currentSlide) => {
    setCurrentPageNumber(carouselRef.current.innerSlider.state.targetSlide);
    carouselRef.current.innerSlider.state.slideCount - 1 === currentSlide ? setIsLast(true) : setIsLast(false);
  };

  const onSearch = () => {};
  const uncaughtErrorHandler = () => {
    setIsLoaderVisible(false);
    notification.error({
      message: dataConstant.NotificationType.Error,
      description: messageConstant.SomethingWentWrong,
    });
    history.push({ pathname: '/home' });
  };

  const onFinish = (FormDetails) => {
    setIsLoaderVisible(true);
    let requestObj = FormDetails;
    requestObj.testResultId = testResultId;
    if (isLast) {
      const safePassData = JSON.parse(localStorage.getItem('safePassData')) || {}; // Retrieve existing value from localStorage or create an empty object if not found
      safePassData.Age = FormDetails.Age; // Update the Age property in safePassData
      safePassData.Race = FormDetails.Race;
      safePassData.Gender = FormDetails.Gender;
      safePassData.IsAlzheimerDisease = FormDetails.IsAlzheimerDisease;
      safePassData.IsAlzheimerDiseaseInFamily = FormDetails.IsAlzheimerDiseaseInFamily;
      safePassData.IsHeadInjuriesConcussion = FormDetails.IsHeadInjuriesConcussion;
      safePassData.IsOtherNeurologicalDisorder = FormDetails.IsOtherNeurologicalDisorder;
      safePassData.IsOtherNeurologicalDisorderInFamily = FormDetails.IsOtherNeurologicalDisorderInFamily;
      safePassData.IsParkinsonDisease = FormDetails.IsParkinsonDisease;
      safePassData.IsParkinsonDiseaseInFamily = FormDetails.IsParkinsonDiseaseInFamily;
      safePassData.IsSinoNasalDisease = FormDetails.IsSinoNasalDisease;

      localStorage.setItem('safePassData', JSON.stringify(safePassData)); // Set the updated safePassData value in localStorage
      httpPost(urlConstant.TestResult.SaveUserMedicalInformation, requestObj)
        .then((response) => {
          if (response) {
            if (response.status === dataConstant.APIStatus.Success) {
              history.push({ pathname: '/result' });
              setIsLoaderVisible(false);
            } else {
              uncaughtErrorHandler();
            }
          } else {
            uncaughtErrorHandler();
          }
        })
        .catch(() => {
          uncaughtErrorHandler();
        });
    }
  };

  return (
    <Spin spinning={isLoaderVisible} tip="Loading...">
      <div className="add_organization_page">
        <header className="d-flex align-items-center justify-content-between">
          <Link className="logo" to="/home">
            <img src="/resources/images/logo-white.png" alt="logo" />
          </Link>
        </header>
        <div className="add_organization_wrapper pt-4 px-4" style={{ backgroundColor: '#db3833' }}>
          <div className="usermedical_wrapper">
            <Form {...layout} form={form} name="userForm" autoComplete="off" onFinish={onFinish}>
              <Carousel
                className="usermedical_slider"
                ref={carouselRef}
                swipeToSlide={false}
                swipe={false}
                infinite={false}
                arrows
                {...settings}
                afterChange={onPageChange}
              >
                <div id="page1">
                  <h3 className="mb-4 text-center">The next series of questions WILL NOT affect your test results:</h3>
                  <Row>
                    <Col className="ant-form-item-label custom_label" span={10}>
                      <label>Age</label>
                    </Col>
                    <Col span={14}>
                      <Row>
                        <Col span={16}>
                          <Slider
                            min={1}
                            max={100}
                            onChange={onChange}
                            value={typeof inputValue === 'number' ? inputValue : 0}
                          />
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            className="w-100"
                            name="Age"
                            rules={[
                              { required: true, message: 'Please enter Age.' },
                              { pattern: dataConstant.AgeValidation.ValidAgeCheck, message: 'Please enter valid Age.' },
                            ]}
                          >
                            <Input
                              type="number"
                              className="w-100"
                              min={1}
                              max={100}
                              value={inputValue}
                              onChange={onChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Form.Item
                    className="select_organization_wrapper p-0"
                    required={false}
                    name="Race"
                    label="Race/Ethnicity"
                    rules={[{ required: true, message: 'Please select a Race/Ethnicity.' }]}
                  >
                    <Select
                      placement={'bottomLeft'}
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                      {dataConstant.Race.map((value) => {
                        return (
                          <Option key={value} value={value}>
                            {value}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    required={false}
                    label="Gender"
                    name="Gender"
                    rules={[{ required: true, message: 'Please select an option.' }]}
                  >
                    <Radio.Group>
                      <Radio value="Male"> Male </Radio>
                      <Radio value="Female"> Female </Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div>
                  <h3 className="my-4 text-center">Have you been diagnosed with the following disorders?</h3>
                  {_.map(dataConstant.UserMedicationQuestions, (item, index) => {
                    return (
                      <Form.Item
                        required={false}
                        label={item}
                        name={index}
                        rules={[{ required: true, message: 'Please select an option.' }]}
                      >
                        <Radio.Group>
                          <Radio value={true}> Yes </Radio>
                          <Radio value={false}> No </Radio>
                        </Radio.Group>
                      </Form.Item>
                    );
                  })}
                </div>
                <div>
                  <h3 className="my-4 text-center">Do you have any of the following family history?</h3>
                  {_.map(dataConstant.UserFamilyMedicationQuestions, (item, index) => {
                    return (
                      <Form.Item
                        required={false}
                        label={item}
                        name={index}
                        rules={[{ required: true, message: 'Please select an option.' }]}
                      >
                        <Radio.Group>
                          <Radio value={true}> Yes </Radio>
                          <Radio value={false}> No </Radio>
                        </Radio.Group>
                      </Form.Item>
                    );
                  })}
                </div>
              </Carousel>
              <div className="text-center action-btn">
                <Button
                  type="primary"
                  htmlType={isLast === true ? 'submit' : 'button'}
                  onClick={(e) => {
                    if (isLast === false) {
                      e.preventDefault();
                    }
                    form
                      .validateFields(dataConstant.UserMedicalFormCheckValues[currentPageNumber])
                      .then(() => {
                        isLast === false ? carouselRef.current.next() : null;
                      })
                      .catch(() => {});
                    if (
                      !form.getFieldValue('Age') ||
                      form.getFieldValue('Age') < 1 ||
                      form.getFieldValue('Age') > 100 ||
                      !form.getFieldValue('Gender') ||
                      !form.getFieldValue('Race')
                    ) {
                      carouselRef.current.goTo(0, false);
                    } else if (
                      form.getFieldValue('IsOtherNeurologicalDisorder') === null ||
                      form.getFieldValue('IsOtherNeurologicalDisorder') === undefined ||
                      form.getFieldValue('IsSinoNasalDisease') === null ||
                      form.getFieldValue('IsSinoNasalDisease') === undefined ||
                      form.getFieldValue('IsHeadInjuriesConcussion') === null ||
                      form.getFieldValue('IsHeadInjuriesConcussion') === undefined ||
                      form.getFieldValue('IsParkinsonDisease') === null ||
                      form.getFieldValue('IsParkinsonDisease') === undefined ||
                      form.getFieldValue('IsAlzheimerDisease') === null ||
                      form.getFieldValue('IsAlzheimerDisease') === undefined
                    ) {
                      carouselRef.current.goTo(1, false);
                    } else if (
                      form.getFieldValue('IsOtherNeurologicalDisorderInFamily') === null ||
                      form.getFieldValue('IsOtherNeurologicalDisorderInFamily') === undefined ||
                      form.getFieldValue('IsParkinsonDiseaseInFamily') === null ||
                      form.getFieldValue('IsParkinsonDiseaseInFamily') === undefined ||
                      form.getFieldValue('IsAlzheimerDiseaseInFamily') === null ||
                      form.getFieldValue('IsAlzheimerDiseaseInFamily') === undefined
                    ) {
                      carouselRef.current.goTo(2, false);
                    }
                  }}
                >
                  {isLast === false ? 'Next' : 'Submit'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <footer>
          <p>
            <b> {dataConstant.footerMessage} </b>
          </p>
        </footer>
      </div>
    </Spin>
  );
};

export default UserMedicationQuestion;
