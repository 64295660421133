import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

const Chart = (props) => {
  const { state } = props.location;
  const getJsonData = state.jsonData;
  const testKitId = state.jsonData.TestKitId;
  const history = useHistory();
  const [data, setData] = useState({
    testKitId: 0,
    average: 0,
  });
  useEffect(() => {
    if (getJsonData) {
      calculateSmellIntensity();
    }
  }, []);

  const calculateSmellIntensity = () => {
    let sumSmellIntensity = 0;
    getJsonData.TestResultDetails.forEach((element) => {
      sumSmellIntensity = sumSmellIntensity + element.SmellIntensity;
    });
    const averageSmellIntensity = sumSmellIntensity / 5;
    setData({
      testKitId: testKitId,
      average: averageSmellIntensity,
    });
  };
  if (!getJsonData) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <header className="d-flex align-items-center justify-content-between">
        <a className="back-btn" href="#">
          <img src="/resources/images/back_arrow.png" alt="arrow" />
        </a>
        <a className="logo" href="#">
          <img src="/resources/images/logo.png" alt="logo" />
        </a>
      </header>
      <section>
        <h3>{'Average Smell Intensity for test kit id ' + data.testKitId + ' is ' + data.average}</h3>
      </section>
      <div>
        <Button
          color="primary"
          onClick={() => {
            history.push({
              pathname: `/result`,
              state: { jsonData: getJsonData },
            });
          }}
        >
          Result Page
        </Button>
      </div>
    </>
  );
};
export default Chart;
