import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Divider, message, Upload, Row, Col, Image, Space, Spin, notification } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import defaultQRCodeImage from './../../../images/NoImage.png';
import urlConstant from '../../../constants/urlConstant';
import { FileUpload, httpGet } from '../../../services/commonService';
import { useHistory } from 'react-router';
import dataConstant from '../../../constants/dataConstant';
import messageConstant from '../../../constants/messageConstant';
const { Dragger } = Upload;
let ScentOptionId = '';
let userRole;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/png';

  if (!isJpgOrPng) {
    notification.error({
      message: messageConstant.manageScentCardData.PngValidation,
      duration: 2,
    });
    return false;
  }

  const isLt2M = file.size / 1024 / 1024 < 5;

  if (!isLt2M) {
    notification.error({
      message: messageConstant.manageScentCardData.MbValidation,
      duration: 2,
    });
    return false;
  }
  return isJpgOrPng;
};
const ManageScentCardData = (props) => {
  const history = useHistory();
  ScentOptionId = props.match.params.Id;
  const [form] = Form.useForm();
  const [scentCardData, setScentCardData] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let LoginUserDetails = localStorage.getItem('user');
    userRole = JSON.parse(LoginUserDetails).roleName;
    if (userRole != dataConstant.Role.Admin) {
      history.push('/admin/dashboard');
    }
  }, []);

  useEffect(() => {
    if (ScentOptionId) {
      httpGet(urlConstant.ScentOption.GetScentCardOptionDetailById + parseInt(ScentOptionId), true).then((response) => {
        if (response.status === dataConstant.APIStatus.Success) {
          form.setFieldsValue({
            Title: response.data.title,
            StoredPath: response.data.storedPath,
            Id: response.data.id,
            OriginalFileName: response.data.originalFileName,
          });
          setScentCardData(response.data);
        }
      });
    }
  }, [ScentOptionId]);

  const onFinish = (values) => {
    setLoader(true);
    if (ScentOptionId) {
      const formData = new FormData();
      const model = {
        Id: parseInt(ScentOptionId),
        Title: values.Title,
      };
      formData.append(
        'file',
        values && values.StoredPath && values.StoredPath.file && values.StoredPath.file.originFileObj
          ? values.StoredPath.file.originFileObj
          : null
      );
      formData.append('model', JSON.stringify(model));

      FileUpload(urlConstant.ScentOption.UpdateScentCardOption, formData, true)
        .then((res) => {
          if (res.status === dataConstant.APIStatus.Success) {
            setLoader(false);

            notification.success({
              message: res.message,
              duration: 2,
            });
            history.push({
              pathname: `/admin/scentCardData`,
            });
            redirectToScentoptionList();
          } else if (res.status === dataConstant.APIStatus.Failure) {
            setLoader(false);
            notification.error(res.error);
            notification.error({
              message: res.message,
              duration: 2,
            });
          } else if (res.status === dataConstant.APIStatus.Warning) {
            setLoader(false);
            notification.error(res.message);
            notification.warning({
              message: res.message,
              duration: 2,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          notification.error({
            message: err,
            duration: 2,
          });
        });
    } else {
      const formData = new FormData();
      const model = {
        Title: values.Title,
      };
      formData.append('file', values.StoredPath.file.originFileObj);
      formData.append('model', JSON.stringify(model));

      FileUpload(urlConstant.ScentOption.CreateScentCardOptionDetail, formData, true)
        .then((res) => {
          if (res.status === dataConstant.APIStatus.Success) {
            setLoader(false);
            notification.success({
              message: res.message,
              duration: 2,
            });
            history.push({
              pathname: `/admin/scentCardData`,
            });
          } else if (res.status === dataConstant.APIStatus.Failure) {
            setLoader(false);
            notification.error({
              message: res.message,
              duration: 2,
            });
          } else if (res.status === dataConstant.APIStatus.Warning) {
            setLoader(false);
            notification.warning({
              message: res.message,
              duration: 2,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          notification.error({
            message: err,
            duration: 2,
          });
        });
    }
  };

  const handleChange = (info) => {
    if (info && info.file && info.file.originFileObj) {
      getBase64(info && info.file && info.file.originFileObj, (url) => {
        setScentCardData({
          ...scentCardData,
          storedPath: url,
        });
      });
    }
  };

  const redirectToScentoptionList = () => {
    history.push({
      pathname: `/admin/scentCardData`,
    });
  };

  if (loader) {
    return (
      <Space size="middle" className="cmLoader">
        <Spin size="small"></Spin>
      </Space>
    );
  }

  return (
    <div>
      <div className="page-header">
        <strong>
          {/* {testKitId || scentCardId ? `Edit Test Kit: ${testKitName}` : "Create Test Kit"} */}
          <h2>Manage scent option</h2>
        </strong>
      </div>
      <div className="row cm-add-scent-wrapper">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="title">
                  <h4>Scent option</h4>
                </div>
              </div>
            </div>
            <div className="cm-add-scent">
              <Form
                name="basic"
                form={form}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                className="row"
              >
                <div className="col-md-6">
                  <Form.Item
                    label={messageConstant.manageScentCardData.lable.ScentOptionName}
                    name={dataConstant.manageScentCardData.formId.Title}
                    id={dataConstant.manageScentCardData.formId.Title}
                    rules={[
                      {
                        required: true,
                        message: messageConstant.manageScentCardData.ruleMessage.ScentOptionName,
                      },
                      {
                        max: 25,
                        message: messageConstant.manageScentCardData.ruleMessage.MaxLimit,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Upload scent card image"
                    name={dataConstant.manageScentCardData.formId.StoredPath}
                    valuePropName={dataConstant.manageScentCardData.formId.StoredPath}
                    id={dataConstant.manageScentCardData.formId.StoredPath}
                    rules={[
                      {
                        required: true,
                        message: messageConstant.manageScentCardData.ruleMessage.UploadImage,
                      },
                    ]}
                  >
                    <Dragger
                      name="OriginalFileName"
                      showUploadList={false}
                      maxCount={1}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                      customRequest={(info) => {
                        // Manage Any event on Uploding file
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <div className="test-kit-qr-code d-flex flex-column">
                    <label className="qr-code-label">Preview Image</label>

                    <div className={`test-qr-code text-center`}>
                      <Image
                        src={(scentCardData && scentCardData.storedPath) || defaultQRCodeImage}
                        width={200}
                        height={200}
                        id={dataConstant.manageScentCardData.formId.StoredPath}
                        name={dataConstant.manageScentCardData.formId.StoredPath}
                        preview={scentCardData && scentCardData.storedPath ? true : false}
                      />
                    </div>
                  </div>
                </div>
                <Divider />
                <Form.Item className="col-12">
                  <Button type="default" htmlType="button" onClick={redirectToScentoptionList}>
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageScentCardData;
