import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Footer from './footer';
import './admin-layout.css';
import { connect } from 'react-redux';
import ApiLoader from './../apiLoader/apiLoader';

class AdminLayout extends React.PureComponent {
  render() {
    const { apiLoader } = this.props;
    return (
      <React.Fragment>
        <NavMenu />
        <div className="admin-root">
          <div className="container">
            <div className="content-wrapper">
              {this.props.children}
              {/* {`Loader: ${(this.props.apiLoader && this.props.apiLoader.isLoading)}`} */}
              {/* {(this.props.apiLoader && this.props.apiLoader.isLoading && <ApiLoader />)} */}
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    apiLoader: state.apiLoader,
  };
};

export default connect(mapStateToProps)(AdminLayout);
