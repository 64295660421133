// import React from 'react'

const messageConstant = {
  Required: 'Please enter ',
  locationError: 'Location tracking is not supported',
  IsActive: 'Active Confirmation',
  IsActiveConfirmation: 'In-activation of organization will also In-activate its associated user.',
  OrganizationDeleteConfirmation: 'Deleting organization will also delete its associated user.',
  SureMessage: 'Are you sure you want to continue?',
  DeleteConfirmation: 'Delete Confirmation',
  DeleteConfirm: 'Are you sure you want to delete?',
  testKit: {
    testKit: 'Test Kit(s)',
  },
  ExportDataNotFound: 'Sorry! There is no data to export.',
  ConfirmPassword: 'New Password and Confirm Password must be same.',
  InvalidPasswordPattern:
    'Password must contain one uppercase, at least 8 characters, one lowercase, one special character.',

  manageUser: {
    firstNameRequired: 'Please enter first name!',
    firstNameCharacterLimit: 'First name allowed upto 25 character',
    lastNameRequired: 'Please enter last name!',
    lastNameCharacterLimit: 'Last name allowed upto 25 character',
    passwordRequired: 'Please enter password!',
    confirmPasswordReqired: 'Please enter confirm password!',
    confirmPasswordDoesNotMatch: 'Confirm password doest not match with password!',
    phoneLimit: 'Phone# allowed between 7-15 character',
    roleRequired: 'Role is required',
  },

  manageScentCardData: {
    PngValidation: 'Please select PNG file only.',
    MbValidation: 'Please make sure file size must be less than 5MB.',
    lable: {
      ScentOptionName: 'Scent option name.',
    },
    ruleMessage: {
      ScentOptionName: 'Please input your scent option name',
      MaxLimit: 'Scent option name must be less than 25 characters.',
      UploadImage: 'Please upload your scent option image!',
    },
  },
  SomethingWentWrong: 'Something went wrong. Please try again!',
  InvalidQRCode: 'Invalid Secret / QR code scanned, Please try again!',
  Result: {
    SendResultInEmailSucess: 'Result send successfully',
    TestComplated: 'Test is already completed',
    TestComplatedWarning: 'You can not proceed to next test',
  },
  Report: {
    ToDate: 'To Date',
    DateValidation: 'To Date should be greater than From Date',
    FromDateValidation: 'Please select From Date',
    ToDateValidation: 'Please select To Date',
    FilterValidation: 'Please select filter option',
  },
  Email: {
    emailRequired: 'Please enter email!',
    emailValid: 'Please enter valid email!',
    emailCharacterLimit: 'Email allowed upto 25 character',
  },
};
export default messageConstant;
